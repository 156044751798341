import React from 'react';

import { Teaser, Row, Column } from '@ard-online/component-styleguide';

const team = [
  {
    "type": "text",
    "title": "Jenny Sommerfeld-Denkl",
    "subline": "Head of ARD Channels International",
    "text": <p className="mail"><a href="mailto:jenny.sommerfeld@wdr-mediagroup.com">jenny.sommerfeld@wdr-mediagroup.com</a></p>,
    "image": {
      "src": require('../images/jenny_sommerfeld_denkl.jpg'),
      "alt": "Jenny Sommerfeld-Denkl"
    }
  },
  {
    "type": "text",
    "title": "Anke Burgmer",
    "subline": "Distribution Manager",
    "text": <p className="mail"><a href="mailto:anke.burgmer@wdr-mediagroup.com">anke.burgmer@wdr-mediagroup.com</a></p>,
    "image": {
      "src": require('../images/anke_burgmer.jpg'),
      "alt": "Anke Burgmer"
    }
  },
  {
    "type": "text",
    "title": "Meryl Marschall",
    "subline": "Distribution Manager",
    "text": <p className="mail"><a href="mailto:meryl.marschall@wdr-mediagroup.com">meryl.marschall@wdr-mediagroup.com</a></p>,
    "image": {
      "src": require('../images/meryl_marschall.jpg'),
      "alt": "Meryl Marschall"
    }
  },
  {
    "type": "text",
    "title": "Cornelia Göppel",
    "subline": "Accounting",
    "text": <p className="mail"><a href="mailto:cornelia.goeppel@wdr-mediagroup.com">cornelia.goeppel@wdr-mediagroup.com</a></p>,
    "image": {
      "src": require('../images/cornelia_goeppel.jpg'),
      "alt": "Cornelia Göppel"
    }
  },
  {
    "type": "text",
    "title": "Claudia Lange",
    "subline": "Senior Assistant and Marketing Coordinator",
    "text": <p className="mail"><a href="mailto:claudia.lange@wdr-mediagroup.com">claudia.lange@wdr-mediagroup.com</a></p>,
    "image": {
      "src": require('../images/claudia_lange.jpg'),
      "alt": "Claudia Lange"
    }
  }
];

export default function ContactSection() {
  return (
    <section id="contact">
      <Row>
        <Column>
          <h2>Contact the ARD Channels International</h2>
          <h3>Our team</h3>
          {team.map((teamMember, index) =>
            <div className="teaserCont" key={index}><Teaser {...teamMember} textPosition="besideImage" /></div>
          )}
        </Column>
      </Row>
      <Row>
        <Column>
          <h3>Address</h3>
          <p>WDR mediagroup GmbH<br />ARD Channels International<br />Ludwigstrasse 11<br />50667 Cologne / Germany</p>
          <p>Phone: +49 221 2035-1951</p>
          <p className="mail">Email: <a href="mailto:ard-channels-international@wdr-mediagroup.com">ard-channels-international@wdr-mediagroup.com</a></p>
          <p className="website">Website: <a href="https://ard-channels-international.com">www.ard-channels-international.com</a></p>
        </Column>
      </Row>
    </section>
  );
}