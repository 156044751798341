import { ChannelLogo } from '@ard-online/component-styleguide';
import ScrollToTop from './ScrollToTop';
import { Column, Row } from '@ard-online/component-styleguide/dist/components/primitives/Grid';
import ChannelMap from './ChannelMap';

// styles.js
import styled from 'styled-components';

export const Root = styled.div`
 
  position: relative;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto 4rem;
  font-size: 112.5%;

  .allYours {
    height: 110px;
    max-width: 100%
  }

  .ardLogo,
  .dasErsteLogo {
    max-width: 200px;
    width: 100%
  }

  @media screen and (min-width: 768px){
    .alignRight {
      float: right
    }
  }
  header[class*="Root"]{
    margin-top: -28px
  }
  h2 {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.4;
    margin: 0
  }
  .teaserCont {
    h3,
    div[class*="Line"],
    div[class*="Text"] {
      font-size: 100%
    }
    div[class*="TeaserWrapper"] > div > div {
      background: 0 none
    }
    div[class*="TeaserContainer"] img,
    div[class*="TeaserContainer"]:hover img{
      -webkit-transition: none !important;
      -moz-transition: none !important;
      -o-transition: none !important;
      transition: none !important;
      transform: scale(1) !important;
    }
  }

  ul li {
    margin-top: 1rem;
  }
  ul li:first-child {
    margin-top: 0
  }

  hr {
    min-width: 120px;
    width: 25%;
    border: 0 none;
    border-top: 1px solid #8ebbff;
    margin: 6rem auto;
  }

  .mt-2 {
    margin-top: 2rem
  }

  .center-vertical {
    display: flex;
    flex-direction: column;
    justify-content: center
  }

  #home {
    margin-top: 6rem
  }

  .references,
  .regional-channels {
    margin-top: 4rem
  }

  .channel-list .channelLogo {
    a:focus img {
      outline: none
    }
    img {
      left: 50%;
      transform: translateX(-50%) scale(1) !important
    }
    &.ard-alpha img {
      width: 65%
    }
    &.one img {
      width: 46%
    }
    &.3sat img {
      width: 90%
    }
    &.phoenix img {
      width: 66%
    }
    &.kika img {
      width: 60%
    }
  }

  .regional-channels .channelLogo {
      a:focus img {
        outline: none
      }
      img {
        padding: 8px;
        box-sizing: border-box;
        left: 50%;
        transform: translateX(-50%) scale(1) !important
      }
      &.br img {
        width: 37%;
        transform: translateX(-50%) translateY(3.5%) scale(1) !important
      }
      &.hr img {
        width: 35%;
        transform: translateX(-50%) translateY(6.5%) scale(1) !important;
      }
      &.mdr img {
        width: 60%;
        transform: translateX(-50%) translateY(1.2%) scale(1) !important;
      }
      &.rbb img {
        width: 53%;
        transform: translateX(-50%) translateY(3%) scale(1) !important;
      }
      &.ndr img {
        width: 49%;
        transform: translateX(-50%) translateY(-4%) scale(1) !important;
      }
      &.sr img {
        width: 36%;
        transform: translateX(-50%) translateY(5.5%) scale(1) !important;
      }
      &.swr img {
        width: 64%;
        transform: translateX(-50%) translateY(1.5%) scale(1) !important;
      }
      &.wdr img {
        width: 54%;
        
      }
      /*div[class*="TeaserWrapper"] > div[class*="Root"] > div[class*="Root"]{
        &:after {
          content: "";
          display: block;
          height: 15px;
          border-top: 1px solid #000;
          width: 100%;
          position: absolute;
          top: calc(50% - 16px);
          border-bottom: 1px solid #000;
        }
      }*/
    }
  }

  #tv-channels {
    .inlineLogo {
      margin-top: -6px
    }
  }

  .service-container {
    width: calc(100% + 20px);
    margin: 0 -10px;
  }
  .service-container .service {
    width: calc(100% - 20px);
    margin: 0 10px 20px 10px;
    background-color: #589FFF;
    color: #fff;
    box-shadow: 5px 6px 10px 0px rgb(0 30 80);
    padding: 15px
  }

  #contact {
    p.mail, p.website {
      a {
        text-decoration: none
      }
      a:focus, a:hover {
        text-decoration: underline
      }
    }
  }

  @media screen and (min-width: 961px){
    .service-container .service {
      width: calc(50% - 20px);
    }
    #home {
      margin-top: 8rem;
      h2 + p {
        margin-top: 2rem
      }
    }
  }

  @media screen and (min-width: 1281px){
    .service-container .service {
      width: calc(33.3333% - 20px)
    }
  }

  @media screen and (min-width: 1281px){

    .channel-list div[class*="TeaserWrapper"] > div[class*="Root"] {
      width: 25%;
      padding: 15px;
      box-sizing: border-box
    }

    .channel-list div[class*="TeaserWrapper"] > div[class*="TeaserTextRoot"] {
      width: 75%
    }

  }

`;

export const Content = styled.div`
  margin-top: 48px
`;

export const Section = styled.div`
  margin-top: 4rem
`;

export const Footer = styled.div`
  background-color: ${props => props.theme.primary.base};
  color: ${props => props.theme.primary.text};
  margin: 0;
  padding: 0;
  .footerLogo {
    height: 38px;
    margin: 10px
  }
`;

export const FooterRow = styled(Row)`
  margin: 0;
  padding: 0
`;

export const FooterColumn = styled(Column)`
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 !important
`;

export const Logos = styled.div`
  display: flex;
  flex-wrap: wrap; 
`;

export const StyledChannelLogo = styled(ChannelLogo)` 
  height: 38px;
  margin: 10px;
  path { fill: ${(props) => props.theme.primary.text}; }
`;

export const StyledScrollToTop = styled.div` 
  position: fixed;
  z-index: 1000;
  bottom: 30px;
  right: 30px;
  box-shadow: 5px 6px 10px 0px rgb(0 30 80);
  transition: opacity 0.2s ease 0s;
  opacity: 1;
  a {
    float: left;
    margin: 0px;
    padding: 0px;
    font-family: Thesis, sans-serif;
    text-decoration: none;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    fill: rgb(255, 255, 255);
    border-color: #005ec8;
    outline: none;
    transform: translate3d(0px, 0px, 0px);
    color: rgb(255, 255, 255) !important;
    background: #005ec8 !important;
  }
  span {
    padding: 0px 0px 0px 16px;
    height: 48px;
    vertical-align: middle;
    display: table-cell;
    line-height: 48px;
    white-space: nowrap;
    -webkit-box-flex: 1;
    flex-grow: 1;
  }
  a span {
    display: none
  }
  svg {
    width: 48px;
    height: 48px;
    display: block;
  }
  svg path {
    path { fill: ${(props) => props.theme.primary.text}; }
  }
  @media screen and (min-width: 768px){
    a span {
      display: inline
    }
  }
`;
export const StyledHeader = styled.header`
  font-size: 112.5%;
  div[class*="ToolbarWrapper"]{
    padding: 10px 0;
    height: auto
  }
  div[class*="Mainmenu"]{
    width: auto;
    display: none
  }
  div[class*="ActionIconsRight"]{
    margin-left: auto
  }
  li[class*="ButtonElement"] a {
    font-size: 100%
  }
  div[class*="Logo"]{
    height: 72px
  }
  #mobileNav {
    position: fixed;
    top: 92px;
    width: 100%;
    background-color: rgb(0,94,200);
    z-index: 998;
    padding: 14px;
    box-sizing: border-box;
  }
  #mobileNav ul {
    margin: 0;
    padding: 0;
    list-style: none
  }
  #mobileNav ul a {
    padding: 8px 0;
    display: inline-block;
    color: #fff;
    text-decoration: none
  }
  #mobileNav ul li.active a span {
    border-left: 4px solid #fff;
    padding-left: 4px
  }
  @media screen and (min-width: 1240px){
    div[class*="ActionIconsLeft"]{
      display: none
    }
    div[class*="Mainmenu"]{
      display: block
    }
    div[class*="Mainmenu"] a span {
      height: 58px;
      line-height: 58px
    }
    div[class*="Title"]{
      justify-content: center
    }
    li[class*="ButtonElement"] a {
      font-size: 111.1111%
    }
    div[class*="LogoWrapper"]{
      margin-right: 202px;
    }
    #mobileNav {
      display: none
    }
  }
`;
export const InteractiveMap = styled.div`

  margin: 0 auto;
  margin-bottom: 3rem;

  img {
    width: 80%;
    display: block;
    margin: 0 auto
  }
  .tile a {
    font-weight: bold;
    text-decoration: none;
  }
  img.standard {
    display: none;
  }
  @media (min-width: 768px){
    width: 80%;
    position: relative;
    img {
      display: inline;
      width: 100%;
      margin: auto
    }
    img.standard {
      display: inline
    }
    img.mobile {
      display: none
    }
    ul {
      list-style: none;
      padding: 0;
    }
    ul li {
      margin-top: 0
    }
    .tile {
      position: absolute;
      width: 23.1%;
      height: 9.6%;
      display: block
    }
    .tile a {
      text-decoration: none;
      color: #fff;
      font-size: 2.5vw;
      font-weight: bold;
      hyphens: auto;
      width: 100%;
      height: 100%;
      display: block;
      animation: color_change 2s infinite alternate;
    }
    .tile a:focus,
    .tile a:hover {
      animation: none
    }
    .tile a:focus span,
    .tile a:hover span {
      text-decoration: underline
    }
    .tile span {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center
    }
    #im-tile1 {
      top: 10.65%;
      left: 52%
    }
    #im-tile2 {
      top: 22%;
      left: 27.1%
    }
    #im-tile3 {
      top: 55.4%;
      left: 2.2%;
    }
    #im-tile4 {
      top: 66.6%;
      left: 52%
    }
    #im-tile5 {
      top: 77.8%;
      left: 27.1%
    }
    #im-tile6 {
      top: 44.1%;
      left: 77.8%;
    }
    .tooltip {
      opacity: 0;
      height: 0;
      overflow: hidden;
      transition: opacity .5s
    }
    .tile a:hover + .tooltip:before,
    .tile a:focus + .tooltip:before {
      content: "";
      display: block;
      width: 28px;
      height: 1px;
      background-color: #fff;
      position: absolute;
      left: calc(50% - 14px);
      top: 0;
      z-index: 20000;
    }
    .tile a:hover + .tooltip:after,
    .tile a:focus + .tooltip:after  {
      content: "";
      display: block;
      height: 20px;
      width: 20px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      background-color: #fff;
      position: absolute;
      top: -11px;
      left: calc(50% - 11px);
      border: 1px solid #ccc;
      z-index: -1
    }
    .tile a:hover + .tooltip,
    .tile a:focus + .tooltip {
      opacity: 1;
      height: auto;
      overflow: visible;
      position: relative;
      box-shadow: 5px 6px 10px 0px rgb(0 30 80);
      z-index: 5000;
      width: 100vw;
      max-width: 320px;
      left: calc(50% - 10px);
      transform: translateX(-50%);
    }
    .tile a:hover + .tooltip .inner,
    .tile a:focus + .tooltip .inner {
      background-color: #fff;
      padding: .5rem;
      display: block;
      border: 1px solid #ccc;
      font-size: 14px;
      z-index: 10000
    }
  }
  @media (min-width: 960px){
    width: 100%;
    .tile a {
      font-size: 1.5vw
    }
  }
  @media (min-width: 1160px){
    .tile a {
      font-size: 17px;
      line-height: 1
    }
  }
  @keyframes color_change {
    from { color: #fff; opacity: 1 }
    to { opacity: .5 }
  }
`;

export const StyledChannelMap = styled.div`

  && {
    height: 0;
    padding-bottom: calc(100% - 100px);
    position: relative;
  }

  .intern-transparenz {
    opacity: 0;
    position: relative;
    z-index: -1
  }

  svg#germany {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0;
    display: inline-block;
    background: #589fff;
    padding-bottom: 12px;
    border: none;
    position: absolute;
  }

  .land {
    fill:#ffffff;
    stroke:#589fff;
    stroke-width:2;
    transition: 1s ease;
  }

  .land.insel {
    fill:#ffffff;
    stroke: none;
  }

  .border {
    stroke-width:2;
    stroke:#589fff;
    stroke-dasharray: 4,4;
    fill: none;
  }

  .pfad line {
    fill: none;
    stroke: #001e50;
    stroke-width: 1;
    stroke-miterlimit: 10;
    stroke-dasharray: 4,4;
  }

  svg#germany a:hover .land,
  svg#germany a:focus .land,
  svg#germany a:active .land {
    fill: #235FA0;
    cursor: pointer;
  }

  a svg.logo {
    overflow: visible;
  }

  a .logo * {
    fill: #ffffff;
    transition: 0.5s ease;
  }

  a:hover .logo *,
  a:focus .logo *,
  a:active .logo *
  {fill: #001e50;}

  a .pfad circle {fill: #001e50;}

  a:hover .pfad circle,
  a:focus .pfad circle,
  a:active .pfad circle {
    fill: #ffffff;
    stroke: #001e50;
    stroke-width: 1;
  }
`;

export const TeaserCont = styled.div`

  div[class*="TeaserContainer"]:hover img{
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
  }

  h3,
  div[class*="Line"] {
    font-size: 100%
  }

  div[class*="Text"]{
    max-height: none;
    font-size: 100%;
  }

  img {
    object-fit: contain !important;
    transform: scale(1) !important
  }
  > div {
    margin-bottom: 1rem
  }
  div[class*="TeaserWrapper"] > div[class*="Root"] > div[class*="Root"]{
    background: none;
  }

`;