import React from 'react';

import { StyledChannelMap } from './styles';

var styles = {
    st0: {
        fill: "#ffffff00"
    }
}

export default function ChannelMap() {
    return (
        <StyledChannelMap className="cssmap">
            <svg id="germany" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 710 575" xmlSpace="preserve">
                <a id="ndr"xlinkHref="http://www.ndr.de/fernsehen/index.html" title="Norddeutscher Rundfunk" alt="Norddeutscher Rundfunk" target="_blank" rel="noopener noreferrer">
                    <path className="land insel" d="M208.7,111.3l4.2-2.7l3.8,1.7l-3.1,3.7l-5.1-2.4L208.7,111.3L208.7,111.3z M219.1,106.6l0.6,2.2l2.4-0.6l5.1-2.8
		L219.1,106.6L219.1,106.6z M231.6,102.4l-1.6,2.4l5,0.2l2.8-1.9L231.6,102.4L231.6,102.4z M240.9,102.1l-1.4,0.7l1.7,1.2l2.1-2
		h-2.4V102.1z M246.8,101.2l-0.9,2h4.6v-2.4L246.8,101.2L246.8,101.2z M253.7,99.6l0.2,2.1l4.5-0.6l0.7-1.5H253.7L253.7,99.6z
		 M261.4,100.2l4.8,0.1l-3.8-2L261.4,100.2L261.4,100.2z"/>
                    <path className="land insel" d="M398,56.3l-0.2-4.3l2.9-3.4l6.4,2.5l3.6,6.9l-6.4,0.2l-3.9-1.5L398,56.3L398,56.3z M286.3,16.7l2.9-3.9l-1-3.7l-2.2,1.1
		l-3.3,8.7l-1.5,10.3l1.1,2.8l1.3-2.5l0.1-6.4l2-1.2l3.2,1.7l4-2.3l-4.2-0.5l-2.4-2.9V16.7L286.3,16.7z M288,31.6l-1.5,2.7l3.6,2.5
		l5.6-1.7c0,0-0.6-4.7-1.1-4.7S288,31.6,288,31.6L288,31.6z M284.4,33.9l-2.4,3.3l3.3,4.5l1.5-2.6l-1.6-3.6L284.4,33.9L284.4,33.9z
		 M294.5,47.4l1.1,3.5l3.3-1l0.1-4.7L294.5,47.4L294.5,47.4z M303.7,50.9l1.5,2.6l3.8-2l0.2-2.6l-3.9-0.2L303.7,50.9L303.7,50.9z
		 M287.8,52.9l-0.7,3.2l2.6,2.4l1.1-2.9L287.8,52.9L287.8,52.9z M292.1,39.4l2.1,1.8l4.1-1.6l-1.7-1.8L292.1,39.4L292.1,39.4z"/>
                    <path className="land insel" d="M511.9,73.5l-1.2-1.2l2.4-1l2.7,4.8l5.2,2.1l4.8,4.7l6.6,6.2l-0.9,4.1l-6.6,0.3l-5.1,1.9l-2.1-1.5l2.5-3.5l-1.5-2.8
		l0.2-1.9l2.6,2.1l4,1.5l2.1-1.5l-3.5-0.7l0.2-1.9l-3.2-2.8l-1.9-2.5l-1.9,4.2l-1.3-1.5l-2-0.7l0.8-3L511.9,73.5L511.9,73.5z
		 M479.9,51.7l-0.1,1.9l2-2.5l1.5-6.1l2.6,0.6l0.9-0.9l1.6-2.7l1.4,3.2l2-3.1l3.1,2l4.5,4l-2.5,3.3l-1.9-3.8l-3-2l-1.6,2.5l-3.2-1.4
		l-3.2,0.4l0.1,2.4h3.3l0.1,3.2l-2.6,2.7l2-0.7l1.6,4.5l-2.5-0.5l-2.5,2.1l5.2,5.5l3-0.1l2.1,2.6l2.5-1l-2.1-2.2l2.8-3.8l5.1-1
		l3.6,2.3l3.1,2.1l2.8-5l-4.1-4.7l-3.8-0.6l-1.6-3.6l5-4.8l-2.1-4l-8.6,0.6L494,41l1.9-5.1l-8.5,3.8l-2.6,5.1l-2-2.3c0,0-1,3.5-1,4
		C481.7,46.9,479.9,51.7,479.9,51.7L479.9,51.7z"/>
                    <path className="land" d="M536,110.6c-1-3.1-0.8-6.7-2.7-9.5l-1.8-0.9l-4.1,1c-4.9-1.5-8.6-4.2-12.5-7.3l4.4-5c-2.3-3.1-6.1-4.4-8-7.4l1.9-4.5
		l-4.1-5.6c-1.9,1.6-6.7,3.9-9.4,3l-0.9,2.8l-3.7-4.5l-2.1-0.8c-1.5-3-5.9-6.4-9.1-7.4c-1.1-4.4-3.5-7.1-5.5-11
		c-1.9,1.2-6.6,4.6-7.4,6.9l-6.2-3.2c-3.5,1.8-7.2,5.3-9.1,8.8l0.2,1.7l-2.2-3.7l-6.6,4.4c-1.6,2-2.6,4.6-4.6,6.3
		c-5.2-1.6-11,2.5-16.6,1c-1.7,2-3.3,5.1-3.8,7.7l-3.4,1.8l-1.6,7.6l-5.9-2.8l-2.6,1.6l-1.2-3.4l-5.4-2.1l-8.7,3l0.8,3.1l-3.7,1
		l1.8-6.1l-4.4-1l1.1-6.7l3.6,0.7l8.8-7.2l-1.2-11l2.8-2.2l-1.6-1.6l-3.4,1.2l-2.2-0.9c-3.1,2.2-6.3,4.2-9.6,6.2
		c-5.6-3.9-11.8-7.3-17.9-10.3c-2.7,1.4-6,5.5-6.5,8.6l0.9-9.1l-3-2c-3.3,0.8-7,1.4-10.4,1.3l5.5-4.1l1.9-10.2L355,37
		c-3,5.9-9.8,8-13.4,13.5c2.3-8,9.7-9.6,12.6-15.2l-0.4-4.2l-2.1-2.4l-3.1,2.6c-3.4-2.9-7.1-5.6-10.7-8.3l-5.3,2.8l-2.3-1.6
		l-4.1,2.1l-3.7-0.4l-2.1-4.3c-3.2-0.9-6.8-2.2-10.1-2.7c-3.6-0.6-7.3,0.4-10.9-1l-2.6,3.8c2,4.8,5.5,14.8,9.5,18.2l-0.8,1.8
		l2.8,1.3l4.2,7.4l-0.2,1.9l-6.2,4.2l-8.5-0.2l-2.2,1.2l2.3,1.7l-1.6,2.1l-3.2-1.5l3.6,6.6l5.5-1.9l3.2,0.9l4.5-2.8
		c-0.8,1.1-3.6,4.9-4.9,5.4l-1.8,4.1l2.7,5.1l3-1l1.1,5.8l-5.6,0.1l3.4,7.8c2.8,3.1,11.9,4.2,15.7,4c5.6,4.4,5.2,7.3,8.4,12
		c1.6,2.4,4,2,5.5,8.3c3.2,1.6,6.4,3.3,9.5,5.2l-0.4,0.4c0,0-7.8-2.3-10.8-4.5c-2.3-7.3-8.8-11.4-11.9-18.2
		c-6.8-3.1-13.3-1-20.4-0.4l-6.6-5.4l-4.7,3.8c-1.7,3.9-3.9,9.8-3.8,14.1c1.5,3.1,2.9,6.3,4,9.5l-2.6,3.4
		c-0.7,5.9,0.4,12.2-1.1,17.9l-2.2-2.3c1.1-5.4,1.5-11.3,1.6-16.9l2.1-3.2l-9.7-6.5l-3,3.8l-0.4,3l3.6,0.9l-2.8,7.6
		c-3-1.2-7.2-4.5-8.7-7.4l4.9-1.6c-1.8-2.7-5.3-9.4-5-12.9c-3.9-0.2-8.4-0.8-12.2-0.6c-6.8,0.5-10.4,3.4-18.1,0.7l-10.1,6.4l1.1,4.4
		l-0.9,2.6l-3.2-0.4l-0.3,11.4c3.7,1,8.3,1.5,12.2,1.1c-6,1.7-4.4,20.1-5.8,24.6c-0.3,0.9-4.2,6.2-4.7,7.5
		c-1.8,4.5-2.5,11.3-3.2,16.2c-3.6-1.3-9.8-1.4-13.4-0.1l-2.4,6.2l2,6.2l8.5,3l2-2.5l3.7,6c-0.9,1.8-2.8,7.4-1.7,9.4l7.8,0.6l6.3-3
		l6.4-4.4l4.2-1.4l-1.1-3.7l2.7-2.1l4,3.5l-0.1,3.3l8.8,0.9l2.2,4.7l-3,7.7l4.3,3l-0.5,3.5l-5.5,1.7l5.1,4.9l5.5-3l5.3-0.4l1.9-3.1
		c0,0,5.1,1.1,5.3,0.7c0.2-0.3,4.8-3.6,4.8-3.6l-1.1-10.1l-0.1-3.9l-6.6-4.1l1.2-2.6l4.9-0.2l2.5-3.8l6.4-0.5l1.5,9.3l7.1-1l8.2-4.9
		l4.3-1.7l0.2,5.4l-2,4.8l-4.8,3.3l4.5,6.3l-2.6,5.1l0.2,2l4-0.6l1.2,3.8l1.5,7.2l4.6,2.4l-2.6,4.1l4,0.3l2.1,2.3l2.7,0.1l-3.8,8.2
		l0.5,4l-1,3.9l2.8-0.2l10.3,0.1l3.4,6.5l-4-0.9l-1.8,5.3l0.9,6.1c0,0-4.3,5.5-4.3,6s5.2,3.4,5.2,3.4l4.8-0.5l0.2-4.3l2.6-2.8
		l2.2,3.5l0.5-3.7l2.4,0.6l0.6-0.3l0.5,1l8.3-3.8l5-4l5.9-1.1l0.8-5l4.7-1l1.2,3.5l5.3-1.5l2.4-0.1l-1.1-3.1l1.2-3l-0.4-5.1
		l-4.9-6.6l3.7-5.6l-0.2-5.4l-2.7-4.2l12.3-0.5l6.2-0.4l-0.1-3.5l4.7-4.4l-0.7-3.7l1.2-3.1l-3.1-6.6l0.6-3.5l-4.2-6l0.4-10l-1.4-1.4
		l-7-9.9l1.2-6.3l5-0.4l4.7-4.5l7.9,0.3l2.6,1.7l8.7-5.2l1.1-4.1l2.9-2.8l-5.1-2.3l-5.1,0.9l-2.5-5.1l4.4,1.3l2.8-2.6l3.7,1.6
		l2.5-1.7l-0.1-5.7l5.5-2.3l2,2.6l10.8-6.1l0.3-3.7l7.1-0.8l4.3,2.1l6.5,6.7l6.2-0.2l4.2,2.6l3.3,1.9l4.7,0.3l3.1,0.9l6.1-4.7
		c0,0,1.5-1.4,1.7-1.4s6.9-2.4,6.9-2.4l2.7,2l4.8-3l1.3-8.6l6-2.8l2.4-4.1l4.3-0.3l-2.5-3.9l2.1-0.6l3.9,2.3l0.1,7l9.9-1.5l3.3,0.7
		l-0.1,4.5l-4.2,5c0,0,5.6,2.2,5.8,2.1c0.2-0.1,4.4-4.7,4.8-4.9c0.4-0.2,2.4-1.9,2.4-1.9C540,122.9,536.9,113.4,536,110.6z
		 M346.8,119.9L346.8,119.9L346.8,119.9L346.8,119.9z"/>
                    <path className="border" d="M409.2,148.9L409.2,148.9l-4.1-1.9l1.6-2l-5.6-1.7l-1.5-4.6l-1.2-3.5l-4.2-1.6l-2.6,3.1l-3.6-2l-1.7-2.8l-5.2-0.1l2.6-6.6
		l6.7-3.4l0.2-4.1l2.8-1.5l3.4-5.4c0,0-4.2-2.5-4.8-2.6c-0.5-0.1-3.8-4.7-3.8-4.7l0.7-8.8 M381.1,131.9L381.1,131.9l-2,0.2l-2.8-0.9
		l-4.7-3.6l-4.5,0.9 M346.8,119.9l-0.5,0.5l-1.9,1.1l0.4,4.2l5.2-0.2l2.8,2.9l4.8-2.7l5.9,4.9l3.6-2l2.2-2.8l-4.1-2.9l-3.8-3.7
		l1.5-6.3l-0.7-2.7l-0.4-4.4l-4.1-0.1l-1.1,3.2l-2.4,0.2l-0.5,2.3l-2.5-0.3l-3.8,3.5l-3.8-1.7l0.1,3.5L346.8,119.9L346.8,119.9z"/>
                    <g className="pfad">
                        <g className="logo">

                            <path d="M91.6,62.4H96v20.8h-4.9l-6.8-12.2v12.2h-4.4V62.4h4.6l7.1,12.9V62.4z M95.9,42.8h2.5v19.6h-2.5V42.8z M95.9,83.3h2.5
				v8.9h-2.5V83.3z M98.4,62.4h7.4c2.6,0,4.4,0.6,5.7,1.9c1.3,1.3,2,3.3,2,6v5.3c0,2.6-0.6,4.6-2,5.8c-1.3,1.3-3.3,1.9-5.7,1.9h-7.4
				V62.4z M103.2,66.4v12.9h2.2l1.5-0.1c0.5-0.1,0.8-0.4,1.2-0.6c0.4-0.4,0.6-0.7,0.8-1.2c0.1-0.6,0.2-1.2,0.2-1.8v-5.4
				c0.1-1.1-0.2-2.1-0.9-2.9c-0.8-0.6-1.8-0.9-2.8-0.8h-2.2C103.2,66.5,103.2,66.4,103.2,66.4z M120.4,75.3v8h-4.6V62.4h7.2
				c1.2,0,2.3,0.1,3.5,0.4c0.8,0.2,1.6,0.6,2.3,1.2c0.6,0.6,1.1,1.2,1.3,2c0.4,0.9,0.5,1.9,0.5,2.8c0,1.2-0.2,2.3-0.8,3.5
				c-0.6,1.1-1.5,1.8-2.7,2.2l4.4,8.8h-4.9l-3.7-8C122.9,75.3,120.4,75.3,120.4,75.3z M120.2,66.2v5.4h2.9c0.8,0,1.5-0.2,2.1-0.8
				s0.8-1.3,0.8-2.1c0.1-0.7-0.1-1.4-0.6-2c-0.7-0.5-1.5-0.6-2.3-0.6L120.2,66.2L120.2,66.2z"/>
                            <path d="M138.8,59.9c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6c3.3,0,6-2.7,6-6s-2.6-5.8-5.7-6C139,59.9,138.8,59.9,138.8,59.9z
				 M138.8,70.6c-2.7,0-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9c2.7,0,4.9,2.2,4.9,4.9c0,0,0,0,0,0.1
				C143.6,68.5,141.5,70.6,138.8,70.6z"/>
                            <polygon points="140.7,67.6 140.7,62.8 135.9,64.6 135.9,65.7 137.4,65.1 137.4,68.8 			" />
                            <rect x="79.9" y="42.7" width="64.9" height="49.5" style={styles.st0}/>
                        </g>
                        <line style={styles.st0} x1="153.4" y1="74" x2="332" y2="74" />
                        <circle cx="347" cy="74" r="5" />
                    </g>
                </a>
                <a id="rb" xlinkHref="https://www.radiobremen.de/programm/index.html" title="Radio Bremen" alt="Radio Bremen" target="_blank" rel="noopener noreferrer">
                    <path className="land" d="M288.8,139.1c0.6,0.4,11.2,5.4,11.2,5.4l3.6,0.4l2.1,1.7l3.3,0.1l-0.3,6.9l-3.4,1.8l-9.2-1.5l-1.2-4.5l-4-4.9l-5-3.8
		l0.7-2.3l1.7,1.7L288.8,139.1L288.8,139.1z"/>
                    <g className="pfad">
                        <g className="logo">
                            <path d="M56.7,150.7c0-0.7,0-1.9-0.1-3.4h1.2c0.1,0.6,0.1,1.2,0.2,1.7l0,0c0.3-0.6,0.8-1.2,1.3-1.6c0.5-0.3,1.1-0.4,1.7-0.4v1.4
				c-0.8-0.1-1.6,0.2-2.3,0.7c-0.5,0.6-0.8,1.5-0.7,2.3v7.1h-1.3C56.7,158.5,56.7,150.7,56.7,150.7z M69.4,156c0,0.6,0,1.5,0.1,2.5
				h-1.1l-0.2-1.6l0,0c-0.4,0.5-0.8,1-1.3,1.3c-0.6,0.3-1.2,0.4-1.9,0.4c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.1-0.7-0.4-1-0.7
				s-0.5-0.6-0.7-1s-0.2-0.9-0.2-1.3c0-0.7,0.1-1.4,0.5-2c0.3-0.5,0.8-0.9,1.4-1.2c0.5-0.2,1.1-0.4,1.7-0.5h2.6v-1.2
				c-0.1-0.5-0.1-0.9-0.2-1.2c-0.2-0.4-0.4-0.7-0.8-0.9s-0.9-0.3-1.3-0.3c-1.1,0-2.1,0.5-2.8,1.2l-0.2-1.4c0.9-0.6,2-0.9,3.1-1
				c0.7,0,1.4,0.1,2,0.5c0.5,0.3,0.8,0.6,1.1,1.1c0.2,0.4,0.3,0.8,0.4,1.3c0,0.3,0.1,0.7,0.1,0.9L69.4,156z M68.2,152.8h-2
				c-0.5,0-1,0.1-1.4,0.2c-0.4,0.1-0.8,0.4-1,0.8c-0.3,0.4-0.4,1-0.4,1.5c0,0.6,0.2,1.1,0.5,1.5c0.4,0.4,0.9,0.6,1.5,0.6
				c0.8,0,1.5-0.3,2-0.9c0.6-0.7,0.9-1.6,0.8-2.5V152.8z M79.9,154.8c0,1.3,0.1,2.5,0.1,3.8h-1.2V157l0,0c-0.6,1.1-1.8,1.7-3.1,1.7
				c-0.5,0-1-0.1-1.5-0.3c-0.4-0.2-0.8-0.5-1.1-0.9c-0.3-0.4-0.5-0.8-0.7-1.3c-0.1-0.5-0.2-0.9-0.3-1.4c-0.1-0.4-0.1-0.9-0.2-1.3v-1
				c0-0.6,0-1.2,0.2-1.7c0.1-0.6,0.3-1.2,0.6-1.7c0.3-0.5,0.7-1,1.2-1.4s1.2-0.5,1.8-0.5c0.6,0,1.2,0.1,1.7,0.5
				c0.5,0.2,0.9,0.7,1.1,1.2h0.1v-6.8h1.3V154.8z M73.3,152.9c0,0.4,0,0.7,0.1,1.1c0,0.5,0.1,1.1,0.3,1.6c0.1,0.5,0.4,1,0.8,1.4
				s0.9,0.6,1.4,0.5c0.5,0,1-0.1,1.4-0.4c0.4-0.3,0.7-0.6,0.9-1c0.2-0.5,0.3-0.9,0.4-1.4s0.2-1.1,0.2-1.6c0-0.9,0-1.8-0.2-2.7
				c-0.2-0.6-0.5-1.1-0.9-1.5s-1-0.6-1.6-0.5c-0.4,0-0.9,0.1-1.3,0.3c-0.4,0.3-0.6,0.6-0.8,1c-0.3,0.5-0.4,1-0.5,1.5
				C73.4,151.7,73.3,152.3,73.3,152.9z M83,142.7h1.6v2H83V142.7z M83.2,147.3h1.3v11.3h-1.3V147.3z M91.5,158.7
				c-0.7,0-1.5-0.1-2.1-0.5c-0.5-0.3-0.9-0.8-1.3-1.3c-0.3-0.6-0.6-1.2-0.7-1.9s-0.1-1.4-0.2-2.1c0-0.7,0.1-1.4,0.2-2.1
				c0.1-0.7,0.4-1.3,0.7-1.9c0.3-0.5,0.7-1,1.3-1.3c0.6-0.4,1.4-0.6,2.1-0.5c0.7,0,1.4,0.2,2,0.5s1,0.8,1.3,1.3
				c0.3,0.6,0.6,1.2,0.7,1.9s0.1,1.4,0.2,2.1c0,0.7-0.1,1.4-0.2,2c-0.1,0.7-0.4,1.3-0.7,1.9c-0.3,0.6-0.8,1.1-1.3,1.4
				C92.9,158.6,92.2,158.7,91.5,158.7z M91.5,157.5c0.6,0,1.1-0.2,1.6-0.5c0.4-0.4,0.7-0.9,0.9-1.4c0.1-0.5,0.2-1,0.3-1.6v-2.4
				c-0.1-0.5-0.2-1-0.4-1.5c-0.1-0.5-0.4-1-0.9-1.3c-0.4-0.4-1-0.6-1.6-0.5c-0.6,0-1.2,0.2-1.6,0.5s-0.7,0.8-0.9,1.3
				c-0.2,0.5-0.3,1.1-0.3,1.6c-0.1,0.4-0.1,0.7-0.1,1.1c0,0.4,0,0.7,0.1,1.1c0,0.6,0.1,1.1,0.3,1.6c0.2,0.5,0.5,1,0.9,1.3
				C90.4,157.4,90.9,157.6,91.5,157.5z M101.2,141.9v6.9l0,0c0.3-0.5,0.7-1,1.2-1.3s1.2-0.5,1.8-0.5c1.2-0.1,2.3,0.6,2.9,1.6
				c0.8,1.3,1.2,2.8,1.1,4.3c0,0.8-0.1,1.5-0.2,2.3c-0.1,0.7-0.4,1.3-0.7,1.9c-0.3,0.5-0.7,0.9-1.3,1.3c-0.6,0.3-1.2,0.5-1.9,0.5
				c-0.4,0-0.8,0-1.2-0.2l-0.8-0.4l-0.6-0.6c-0.2-0.2-0.3-0.5-0.4-0.8H101c0,0.6-0.1,1.2-0.2,1.8h-2.6c0.1-0.7,0.2-1.5,0.2-2.2V142
				h2.8C101.2,142,101.2,141.9,101.2,141.9z M101.2,152.8c0,0.5,0,1,0.1,1.5c0.1,0.4,0.2,0.9,0.4,1.3c0.2,0.3,0.4,0.6,0.7,0.9
				c0.3,0.2,0.7,0.3,1,0.2c0.6,0.1,1.2-0.3,1.5-0.9c0.4-1,0.5-2,0.5-3.1c0.1-1-0.1-1.9-0.5-2.8c-0.3-0.6-0.9-1-1.6-1
				c-0.3,0-0.7,0.1-0.9,0.3c-0.3,0.2-0.5,0.5-0.7,0.8c-0.2,0.4-0.3,0.8-0.4,1.3S101.1,152.3,101.2,152.8z M110.6,149.2
				c0-0.7,0-1.4-0.1-2.1h2.5c0.1,0.7,0.1,1.4,0.1,2.1h0.1l0.3-0.8l0.6-0.7l0.8-0.5c0.4-0.2,0.8-0.2,1.2-0.2h0.5v2.6h-0.8l-0.9,0.2
				c-0.3,0.1-0.6,0.2-0.8,0.5c-0.2,0.3-0.4,0.5-0.5,0.9s-0.2,0.9-0.2,1.3v6.3h-2.8L110.6,149.2z M126.2,158
				c-1.1,0.5-2.2,0.7-3.4,0.7c-0.8,0-1.6-0.1-2.4-0.4c-0.6-0.3-1.2-0.8-1.6-1.3c-0.4-0.6-0.7-1.2-0.9-1.9c-0.2-0.7-0.3-1.5-0.3-2.2
				c0-0.8,0.1-1.6,0.3-2.4c0.2-0.7,0.5-1.4,1-2c0.4-0.5,0.9-0.9,1.4-1.3c0.6-0.3,1.3-0.5,2-0.5c0.6,0,1.2,0.1,1.8,0.3
				c0.6,0.2,1.1,0.5,1.5,0.9c0.5,0.6,0.8,1.2,0.9,2c0.3,0.9,0.4,1.9,0.4,2.9v0.5h-6.7c-0.1,0.8,0.2,1.6,0.7,2.3
				c0.6,0.6,1.4,0.9,2.3,0.8c1.1,0.1,2.1-0.2,3-0.9L126.2,158z M124.2,151.8c0-2-0.6-3-2-3l-0.9,0.2c-0.3,0.2-0.5,0.4-0.6,0.7
				c-0.2,0.3-0.3,0.6-0.3,0.9c-0.1,0.4-0.1,0.7-0.2,1.1h4V151.8z M129.4,149.1c0-0.7,0-1.3-0.1-2h2.7c0.1,0.6,0.1,1.2,0.1,1.8h0.1
				c0.1-0.3,0.2-0.5,0.4-0.7s0.4-0.4,0.6-0.6l0.8-0.5l1.1-0.2c0.6,0,1.1,0.1,1.6,0.4c0.5,0.3,1,0.8,1.2,1.4c0.4-0.6,0.9-1,1.5-1.4
				c0.5-0.3,1.1-0.4,1.6-0.4s1,0.1,1.5,0.3c0.4,0.2,0.8,0.5,1,0.9c0.3,0.4,0.5,1,0.5,1.5c0.2,0.6,0.2,1.2,0.2,1.9v7.1h-2.8v-7.2
				c0-0.6-0.1-1.1-0.3-1.6c-0.3-0.3-0.7-0.5-1.1-0.5c-0.6,0-1.1,0.3-1.4,0.7c-0.3,0.5-0.5,1.1-0.5,1.7v6.9h-2.7v-7.2
				c0-0.6-0.1-1.1-0.4-1.6c-0.3-0.3-0.7-0.5-1.1-0.5c-0.6,0-1.1,0.2-1.4,0.7c-0.3,0.5-0.5,1.1-0.5,1.7v6.9h-2.7L129.4,149.1
				L129.4,149.1z M155.4,158c-1.1,0.5-2.3,0.7-3.4,0.7c-0.8,0-1.6-0.1-2.4-0.4c-0.6-0.3-1.2-0.7-1.6-1.3c-0.5-0.6-0.8-1.2-1-1.9
				s-0.2-1.5-0.2-2.2c0-0.8,0.1-1.6,0.3-2.4c0.1-0.7,0.5-1.4,0.9-2c0.4-0.5,0.9-1,1.5-1.3s1.3-0.5,2-0.5c0.6,0,1.2,0.1,1.7,0.3
				c0.6,0.2,1.1,0.5,1.5,0.9c0.5,0.6,0.8,1.2,0.9,2c0.3,0.9,0.4,1.9,0.4,2.9v0.5h-6.7c-0.1,0.8,0.2,1.6,0.7,2.3
				c0.6,0.6,1.4,0.9,2.3,0.8c1.1,0.1,2.1-0.2,3-0.9L155.4,158z M153.3,151.8c0-2-0.6-3-1.9-3c-0.3,0-0.7,0.1-0.9,0.2
				c-0.3,0.2-0.5,0.4-0.6,0.7c-0.2,0.3-0.3,0.6-0.4,0.9c-0.1,0.4-0.1,0.7-0.2,1.1h4L153.3,151.8L153.3,151.8z M158.5,149
				c0-0.6,0-1.3-0.1-1.9h2.5c0.1,0.7,0.2,1.3,0.2,1.7l0,0c0.3-0.6,0.7-1,1.3-1.4c0.5-0.3,1.2-0.5,1.8-0.5s1.1,0.1,1.6,0.3
				c0.4,0.2,0.8,0.5,1,0.9c0.3,0.4,0.5,0.8,0.5,1.3c0.1,0.5,0.2,1.1,0.2,1.6v7.4h-2.7v-6.8c0.1-0.7-0.1-1.4-0.4-2
				c-0.3-0.4-0.7-0.6-1.2-0.5c-0.6,0-1.1,0.2-1.5,0.7c-0.3,0.6-0.5,1.3-0.5,2v6.7h-2.7L158.5,149L158.5,149z"/>
                            <path d="M175.3,139c-3.2,0-5.8,2.6-5.8,5.8s2.6,5.9,5.8,6s5.9-2.6,6-5.8c0-3.2-2.6-5.9-5.8-6C175.4,139,175.4,139,175.3,139z
				 M175.3,149.6c-2.6,0-4.7-2.2-4.7-4.9c0-2.6,2.2-4.7,4.9-4.7c2.6,0,4.7,2.2,4.7,4.8C180.2,147.5,178,149.6,175.3,149.6
				C175.4,149.6,175.3,149.6,175.3,149.6z"/>
                            <polygon points="177.2,146.6 177.2,141.8 172.4,143.5 172.4,144.8 174,144.1 174,147.7 			" />
                            <path d="M50.1,145.9c-0.6-1.4-1.4-2.6-2.5-3.6c-1-1.1-2.2-1.9-3.6-2.4c-2.8-1.3-6.1-1.3-8.9,0c-1.3,0.6-2.6,1.4-3.6,2.4
				c-1.1,1-1.9,2.3-2.5,3.6c-0.6,1.4-0.9,2.9-0.9,4.5c0,1.5,0.3,3.1,0.9,4.5s1.4,2.6,2.5,3.6c1,1,2.3,1.9,3.6,2.4
				c2.8,1.3,6.1,1.3,8.9,0c1.4-0.5,2.6-1.4,3.6-2.4c1.1-1,1.9-2.2,2.5-3.6c0.6-1.4,0.9-2.9,0.9-4.5C51,148.8,50.7,147.3,50.1,145.9z
				 M47.4,153.2c-0.3,0.9-0.8,1.8-1.4,2.5c-0.6,0.7-1.4,1.4-2.2,1.9s-1.7,0.8-2.7,1v-8.3H38v8.3c-1-0.2-1.9-0.6-2.7-1
				c-0.8-0.5-1.5-1.2-2.2-1.9c-0.6-0.8-1-1.6-1.4-2.5c-0.3-0.9-0.5-1.9-0.5-2.9c0-1.1,0.2-2.3,0.6-3.3c0.4-1,1-1.9,1.8-2.7
				s1.7-1.4,2.7-1.8c1-0.5,2.1-0.7,3.2-0.7c1.1,0,2.3,0.2,3.3,0.7c1,0.4,1.9,1,2.7,1.8c0.7,0.8,1.3,1.7,1.7,2.7s0.7,2.2,0.6,3.3
				C47.9,151.3,47.7,152.3,47.4,153.2z"/>
                            <rect x="28.1" y="138.4" style={styles.st0} width="153.2" height="23.5" />
                        </g>
                        <line style={styles.st0} x1="187.1" y1="152" x2="289" y2="152" />
                        <circle cx="304" cy="152" r="5" />
                    </g>
                </a>
                <a id="wdr" xlinkHref="http://www1.wdr.de/fernsehen/startseite/index.html" title="Westdeutscher Rundfunk" alt="Westdeutscher Rundfunk" target="_blank" rel="noopener noreferrer">
                    <path className="land" d="M184.2,342.6l6.3,1.6l3.5,0.5l6.4,0.3l1-4.2l-0.3-5l3.7,1.6l2-3.1l2.3-3.5l6-3.1l7-0.8l6.2-1.5l2.9-3.9l9.2-2l5.1-4.4
		l3.1-2.6l0.5-5.6l2.7-0.3l3,4.7l3.5,1v5.1l4,5.4l3.3,1.4l2-2.6l-1.5-4.7l4.8-5.3l3.5,1.2l4.6-2.8l1.4-2.9l3.9-5.3l-0.9-5.2l2.3-3.1
		l6.7,0.5l0.4-2.7l3.1-3.4l-2.3-6.4l-4.9,2.2l-1-4l4.6-3.9l8.6-1.4l4.2-2.2l-1.6-5.5l4.4-1.6l3.9,3.4l2.8,1.7l4.1-4.5l2.2-4.2
		l2.6-4.9l1.2-4.4l-0.5-4l3.8-8.2l-2.7-0.1l-2.1-2.3l-4-0.3l2.6-4.1l-4.6-2.4l-1.5-7.2l-1.2-3.8l-4,0.6l-0.2-2l2.6-5.1l-4.5-6.3
		l4.8-3.3l2-4.8l-0.2-5.4l-4.3,1.7l-8.2,4.9l-7.1,1l-1.5-9.3l-6.4,0.5l-2.5,3.8l-4.9,0.2l-1.2,2.6l6.6,4.1l0.1,3.9l1.1,10.1
		c0,0-4.6,3.3-4.8,3.6c-0.2,0.3-5.3-0.7-5.3-0.7l-1.9,3.1l-5.3,0.4l-5.5,3l-5.1-4.9l5.5-1.7l0.5-3.5l-4.3-3l3-7.7l-2.2-4.7l-8.8-0.9
		l0.1-3.3l-4-3.5l-2.7,2.1l1.1,3.7l-4.2,1.4l-6.4,4.4l-6.3,3l-8-0.5c-3.3,2.1-6.3,5.6-8.9,8.5l-4.5,0.3l-2.3,3.8l4.7,6.5
		c-0.8,2.3-3.6,5.2-5.8,6l-2.8-1.1c-2.4,1.7-7.1,3.3-10.1,2.8l-1.5,2c-2.8-2.1-6.3-4.2-9.6-5.5l-1.9,0.9l2.6,3.9l-2.5,1.4l-5.6-2
		l-3,0.7l2.1,3.9l-1,3l1.8,0.6c-0.1,1.6,1.5,4,2.7,4.9l0.1,3.8l4.7,6.9l-0.5,11.6c-1.8,1.7-7.5,7.4-7.7,10.1l0.9,3
		c2.1-1.6,4.6-4.3,4.3,0.1c-3.2,2.4-8.7,5.5-11.1,8.8l-2.6-1.6l-1.5,3.8l1.3,3.9l5.4-1.8l-0.2,3.7l3.8,1.8l-4,7.1l-2.8,1.5l1.7,2.4
		l-1,2.6l6.3,4.4l1,2.8l4.3,1.7l-1,2.6l-3.5,2.2l2.9,4.6l3-0.5l2.9,4.9l-2.8,3.2l0.9,1.3L184.2,342.6L184.2,342.6z"/>
                    <g className="pfad">
                        <g className="logo">
                            <path d="M99,259.9c0.5,0.4,0.9,0.8,1.3,1.3c0.5,0.6,0.9,1.2,1.3,1.8l3.1,5.3h-5.9l-3.6-5.8c-0.2-0.4-0.6-0.8-1-1
				c-0.6-0.3-1.3-0.4-2.1-0.4h-0.4v7.2H86V261h-0.1c-0.2,1-0.7,2-1.3,2.9c-0.6,0.9-1.4,1.7-2.2,2.4c-1,0.7-2.1,1.2-3.3,1.5
				c-1.5,0.4-3,0.6-4.5,0.6h-6.9v-16.8h-0.1l-4.5,16.8H57l-3-13h-0.1l-3.1,13h-6.2l-5.4-20.3h6.1l1.9,8.2c0.2,0.6,0.3,1.3,0.4,2
				c0.2,0.7,0.3,1.4,0.4,2.1l0.5-2.1l0.5-2.1l1.6-8.1h6.8l1.6,8.1c0.2,0.7,0.3,1.5,0.4,2.1c0.1,0.6,0.3,1.4,0.4,2.1
				c0.1-0.6,0.2-1.3,0.4-2c0.1-0.7,0.2-1.4,0.4-2.1l1.9-8.2h12.4c1.5,0,2.9,0.1,4.3,0.5c1.2,0.3,2.3,0.8,3.3,1.5
				c0.9,0.6,1.6,1.3,2.2,2.2s1,1.8,1.3,2.8h0.1v-7h8.8c2.6,0,4.7,0.5,6.1,1.5c1.4,1.1,2.2,2.8,2.1,4.7c0,0.8-0.1,1.5-0.4,2.2
				c-0.2,0.6-0.5,1.1-0.9,1.7c-0.4,0.4-0.8,0.8-1.3,1L99,259.9L99,259.9z M75.1,263.7c1.4,0.1,2.8-0.4,3.9-1.4
				c0.9-1.2,1.3-2.7,1.2-4.2c0-1.9-0.4-3.2-1.3-4.1s-2.2-1.3-4-1.3h-1.5v11H75.1z M94.7,256.9c0.7,0,1.4-0.2,1.9-0.6
				c0.4-0.4,0.7-1,0.6-1.7c0-1.5-0.9-2.2-2.8-2.2h-2.8v4.5H94.7z"/>
                            <path d="M111.5,245.3c-3.3,0-5.9,2.7-5.9,5.9s2.7,5.9,5.9,5.9s5.9-2.7,5.9-5.9S114.9,245.3,111.5,245.3
				C111.6,245.3,111.6,245.3,111.5,245.3z M111.5,256.1c-2.7,0-4.9-2.2-4.9-4.9s2.2-4.9,4.9-4.9c2.7,0,4.9,2.2,4.9,4.9v0.1
				C116.4,253.9,114.2,256.1,111.5,256.1z"/>
                            <polygon points="113.5,253 113.5,248.2 108.6,249.9 108.6,251.1 110.2,250.5 110.2,254.2 			" />
                        </g>
                        <line style={styles.st0} x1="126.7" y1="256" x2="244" y2="256" />
                        <circle cx="258" cy="256" r="5" />
                        <rect x="39.2" y="244.6" style={styles.st0} width="78.2" height="24.8" />
                    </g>
                </a>
                <a id="sr" xlinkHref="https://www.sr.de/sr/home/fernsehen/index.html" title="Saarländischer Rundfunk" alt="Saarländischer Rundfunk" target="_blank" rel="noopener noreferrer">
                    <path className="land" d="M226,431.2l-1.9-0.1l-3.3-5l4.1-5.5l-2-5.4l-4.2-2.9l2.6-8.6l-2.4-3.2l-3.9-0.6l-7.9-4l-10.7,5.4l-7.3,1.6l-9.8-2.7
		l-0.7,7.2l1,0.5l2.4-0.5c2.6,1.6,6.6,5.7,7.1,8.9l-0.7,3.3l3.2,1.9l1,4.1l1.7,1.1v2.6l4.6,1.2l1.2-3.9c2.2,0.2,4.4,0.4,6.6,0.7
		l2.4,5.4c5.3,0.8,9.4,1.1,14.7,0.7L226,431.2L226,431.2z"/>
                     <g className="pfad">
                        <g transform="matrix(0.24, 0, 0, 0.24, 25, 389) scale(0.36)" className="logo">
                            <path d="M346.6,219.2c0,11.3-2.5,22.4-7.2,32.6c-4.5,9.8-10.9,18.3-19.1,25.4c-9.3,7.9-20.1,13.7-31.7,17.3
				c-13.1,4.6-26.9,6.8-40.8,6.3c-16.8-0.1-33.5-1.9-49.9-5.4l2.7-51.7c16.3,6,33.4,9,50.8,9c8.9,0.6,17.8-1.6,25.4-6.3
				c6.6-4,10.4-11.4,9.9-19.1c0.8-6.4-2-12.6-7.2-16.4c-5.1-4.5-10.9-8.2-17.3-10.8l-23.6-8.1c-8.3-3.3-16.3-7.6-23.6-12.7
				c-6.9-5.6-12.7-12.3-17.3-20c-5.4-10.3-8-22-7.2-33.6c-0.3-10.7,1.9-21.1,6.3-30.8c4.5-9.2,11-17.3,19.1-23.6
				c8.9-6.9,19.1-12.1,29.9-15.4c13.3-3.8,27-5.7,40.8-5.4c14.6,0.4,29.2,1.9,43.5,4.5l-2.7,48.1c-14.1-4.2-28.8-6.3-43.5-6.3
				c-8.9-0.7-17.9,1.5-25.4,6.3c-5.1,4.2-8.1,10.6-8.1,17.3c-0.3,6.1,2.4,11.8,7.2,15.4c5.1,4.2,11,7.6,17.3,9.9l23.6,9.9
				c8.1,3.2,15.7,7.4,22.7,12.7c7.4,5.2,13.6,12.1,18.2,20C344.5,197.8,346.9,208.5,346.6,219.2z M495.4,296.3L437.4,202h-1.8v94.3
				h-55.4V55l75.3-1.8c12.8-0.3,25.6,1,38.1,3.6c10.3,2.5,20.1,6.9,29,12.7c7.7,5.9,13.6,13.7,17.3,22.7c4.6,10.2,6.8,21.4,6.3,32.6
				c0.3,7.7-1,15.4-3.6,22.7c-2.5,7.2-6.2,13.9-10.8,20c-4.5,6.1-10,11.3-16.4,15.4c-6.8,4.1-14.1,7.1-21.8,9l18.2,27.2l51.7,77.1
				L495.4,296.3z M488.1,129.3c0.5-8.5-2.9-16.8-9-22.7c-8-6.2-18.1-9.2-28.1-8.1h-15.4v61.7h14.5c10,0.5,19.9-2.4,28.1-8.1
				C484.2,146.1,487.9,137.9,488.1,129.3z"/>
                            <path d="M646,20.5c-37.6,0-68,30.4-68,68s30.4,68,68,68s68-30.4,68-68c0.5-37-29.2-67.5-66.2-68C647.2,20.5,646.6,20.5,646,20.5z
				 M646,143.9c-31.1,0-56.3-25.2-56.2-56.3c0-31.1,25.2-56.3,56.3-56.2c31.1,0,56.3,25.2,56.2,56.3c0,0.3,0,0.6,0,0.9
				C701.7,119.2,676.7,143.9,646,143.9z"/>
                            <polygon points="667.7,108.5 667.7,53.2 612.4,73.1 612.4,86.7 630.6,80.4 630.6,122.1 			" />
                            <rect x="187.9" y="323.5" width="376.5" height="92.6" />
                        </g>
                        <line style={styles.st0} x1="90" y1="411" x2="186" y2="411" />
                        <circle cx="204" cy="411" r="5" />
                        <rect style={styles.st0} x="41.2" y="390.1" width="45.5" height="35.4" />
                    </g>
                </a>
                <a id="swr" xlinkHref="https://www.swrfernsehen.de/index.html" title="Südwestrundfunk" alt="Südwestrundfunk" target="_blank" rel="noopener noreferrer">
                    <path className="land" d="M371.9,469.9l-2.7-2.1l0.9-4.8l-0.2-9.9l-10.6-11.3l1.5-2.8l-5.6-4.3l-0.3-5.4l-0.7-3.6l1.1-4.3l-1.5-4.3l1.2-3.9
		c0,0-4-5.1-4.3-5.2s-0.4,5.4-0.4,5.4l-4.3,0.1l-0.5-7.2l-4.4,2.3l1.1-5l-2.6-8l-3.1,0.7l-3.9,1.4l1-6.6c0,0-5.3,0.2-5.9-0.6
		c-0.6-0.8-7.8,0.9-8.3,0.9c-0.5-0.1-4,3.2-4,3.2l4.8,0.4l1.7,3.4l-2.4,3.4l-4.1-0.2l-2.9,4.9l-5.9,0.2l1.2,3.1l-5.9,2.6l-5.8,6.8
		l-3.9-1.4l5-6.8l-7.2-1.7l-2.9-5.1l-4.6,0.2l0.4,5.3l-3.3-2l-2.1,0.6l-2.1,0.6l-3.2-10.8l5.6-5.6l-4-4l-1.7-10.2l-6.1-5.8l-14,3.6
		l-6.2-7.4l6.6-3.4l1.4-6.2l3.5-3l7.2-1.5l-8.4-9.6l1.7-9.2l5.4-0.2l2.1-4.3c0,0-0.3-6.7-0.2-6.7l-3.3-1.4l-4-5.4v-5.1l-3.5-1
		l-3-4.7l-2.7,0.3l-0.5,5.6l-3.1,2.6l-5.1,4.4l-9.2,2l-2.9,3.9l-6.2,1.5l-7,0.8l-6,3.1l-2.3,3.5l-2,3.1l-3.7-1.6l0.3,5l-1,4.2
		l-6.4-0.3l-3.5-0.5l-6.3-1.6l-3.4-1.7l-0.3,0.3l3,4.4c-0.9,1.1-3.5,2-4.9,2.2c-0.8,1.4-3.2,4.4-4.9,5l-0.1,2.8l-4.1,1.1
		c0.6,10.1,6.5,27.2,19,27.1c-1.7,4.8-5.2,13-9.2,16.4l0.4,1l9.4,1.7l7.3-1.6l10.7-5.4l7.9,4l3.9,0.6l2.4,3.2l-2.6,8.6l4.2,2.9
		l2.1,5.4l-4.1,5.5l3.2,5l3.9,0.3l2.3,1.4l1,3.3c2.2,0.8,5.5,2.8,6.9,4.9l6.3-0.9l3,1.9l3.7-1.5l1.7,3.2c3.9,1.6,7.7,3.2,11.6,4.9
		c-1.5,2.4-3.3,4.8-4.6,7.3c-0.4,0.6-1.4,3.9-1.7,4.3c-1.7,1.9-4.6,3.4-6.4,5.2c-3.3,3.2-6.5,7-7.9,11.4c-0.4,1.4-0.4,3.1-0.9,4.5
		c-1.4,4-4.8,8.6-4,13c-1.3,0.8-7.2,15.8-8.5,18.4l2.4,5.7c-2.4,4.2-6.2,18.4-6.1,23.4l3.8,4.6l3.2,0.9l-1.1,3l2.9,0.9
		c2-1.1,7-3.3,9.4-2.9l-0.1,1.7l4.3,0.7c3.1-1.5,10-4.4,13.6-4.1l5.2,3.5l4.5-1.1l2.2-3.2l2.9,2.2l1.8-4.9l-5.6,1.1l-4.5-1.7
		l3.7-6.4l4.3-0.7l0.4-3l3.4,3.4l2.6-0.5l0.5,4.1l1.7,1.6l4.5-1.7l1.1,5.1h4.7l2.2-2.4c6,0.9,13.2,6.6,18,9.9l11.6-1.1l5.5-2l5-4.8
		l4.2,1l4.3-0.9l2.8,2.5l2.5-3.6v-5.6l0.5-3.2l-1.4-3.2l0.3-6.4l2.4-5.8l-2-6.8l-1.1-8.5l-2.8-5.8l-2-5.2l4.4-4.5l9.2-2.8l3.6-5.1
		l-1.3-4.7l-0.2-3.9l4.9,2.3L371.9,469.9z"/>
                    <path className="border" d="M264.4,448.6l6.8-8.6l1.4-10.1l3-2.5c0,0,2.8-6.3,2.9-6.7c0.1-0.4-1.2-5.5-1.2-5.5l0.5-7.1" />
                    <g className="pfad">
                        <g className="logo">
                            <path d="M116.1,464.4c-3.3,0-5.8,2.6-5.8,5.8s2.6,5.8,5.8,5.8s5.8-2.6,5.8-5.8v-0.1C121.9,467,119.3,464.4,116.1,464.4
				L116.1,464.4z M116.1,474.9c-2.7,0-4.8-2.1-4.8-4.8s2.1-4.8,4.8-4.8s4.8,2.1,4.8,4.8l0,0C120.8,472.8,118.7,474.9,116.1,474.9z"
                            />
                            <polygon points="117.9,472 117.9,467.3 113.2,468.9 113.2,470.1 114.7,469.6 114.7,473.1 			" />
                            <polygon points="87.2,466 87.2,473.9 91.4,478.1 87.2,482.3 87.2,490.2 99.2,478.1 			" />
                            <polygon points="60.7,467.3 58.5,480.8 56.4,467.3 55.2,467.3 52.4,467.3 51.2,467.3 49.1,480.8 46.9,467.3 41.7,467.3 
				45.7,489.1 47.5,489.1 50.7,489.1 51.3,489.1 53.8,475.1 56.3,489.1 56.9,489.1 60.1,489.1 62,489.1 65.8,467.3 			"/>
                            <path d="M83.6,489.1h-6.1l-5.2-8.5h-0.2v8.5H67v-21.7c1.8-0.2,4.3-0.2,6.8-0.2c5.2,0,8.1,1.8,8.1,6.4c0.1,2.9-1.8,5.5-4.7,6
				l1.6,2.5L83.6,489.1z M76.8,474.1c0-1.7-1.1-2.8-3.4-2.8h-1.4v5.6h1.3c2.1,0,3.4-1.1,3.4-3"/>
                            <path d="M33.1,489.4c-1.5,0-3.1-0.2-4.5-0.5l0.2-4.8c1.5,0.6,3.1,0.9,4.7,1c1.8,0,3.2-0.8,3.2-2.3c0-3.7-8.6-1.8-8.7-9.1
				c-0.1-4.1,3.3-6.8,8.7-6.8c1.3,0,2.7,0.2,3.9,0.4v4.4c-1.3-0.4-2.7-0.6-4.1-0.6c-2.2,0-3.2,0.9-3.2,2.1c0,3.4,8.7,2.1,8.7,8.9
				C42,486.3,38.6,489.4,33.1,489.4"/>
                            <polygon points="98,466 98,473.9 102.3,478.1 98,482.3 98,490.2 110,478.1 			" />
                        </g>
                        <line style={styles.st0} x1="132.1" y1="478" x2="271" y2="478" />
                        <circle cx="286" cy="478" r="5" />
                        <rect style={styles.st0} x="27.4" y="464.3" width="95.5" height="25.9" />
                    </g>
                </a>
                <a id="hr" xlinkHref="https://www.hr-fernsehen.de/" alt="Hessischer Rundfunk" target="_blank" rel="noopener noreferrer">
                    <path className="land" d="M326.3,255.7l10.3,0.1l3.4,6.5l-4-0.9l-1.8,5.3l0.9,6.1c0,0-4.3,5.5-4.3,6s5.2,3.4,5.2,3.4l4.8-0.5l0.2-4.3l2.6-2.8
		l2.2,3.5l0.5-3.7l2.4,0.6l2,7.2l8.1,6.6l2.9,3.6l-3.6-0.6v5l1.4,6l-5-0.7l-4.1,5.4l-1.4,2.7l3.2,3.9l-3.8,1.7l-3.4,14.8
		c0,0,2.9-1.8,3.4-1.8s4.3-0.4,4.3-0.4l-0.7,10.5l-6.7,7.6l-5.4-1.1l-2.6,0.7l-0.4,6.9l-4.8,5.3l-6.2-0.2l0.5,7.9l-1.7,3.2l-5.2-3.6
		l-7.8,1.8l-2.9-1.3l-5.5,1.7l3.4,5.6l-2.8,2.8l0.6,10.1l4.6,8.1l-4,7.9l3,7.5l-5.9,2.6l-5.8,6.8l-3.9-1.4l5-6.8l-7.2-1.7l-2.9-5.1
		l-4.6,0.2l0.4,5.3l-3.3-2l-4.2,1.2l-3.2-10.8l5.6-5.6l-4-4l-1.7-10.2l-6.1-5.8l-14,3.6l-6.2-7.4l6.6-3.4l1.4-6.2l3.5-3l7.2-1.5
		l-8.4-9.6l1.7-9.2l5.4-0.2l2.1-4.3l-0.2-6.7l2-2.6l-1.5-4.7l4.8-5.3l3.5,1.2l4.6-2.8l1.4-2.9l3.9-5.3l-0.9-5.2l2.3-3.1l6.7,0.5
		l0.4-2.7l3.1-3.4l-2.3-6.4l-4.9,2.2l-1-4l4.6-3.9l8.6-1.4l4.2-2.2l-1.6-5.5l4.4-1.6l3.9,3.4l2.8,1.7l4.1-4.5l2.2-4.2l2.6-4.9
		c0,0,0.1-0.5,0.2-0.5L326.3,255.7L326.3,255.7z"/>
                    <g className="pfad">
                        <g className="logo">
                            <path d="M41,344.9c6.7,2.4,13.8,3.7,20.9,3.5c7.1,0,14.2-1.1,20.9-3.5v2.1c-6.7,2.4-13.8,3.5-21,3.5c-7.1,0.1-14.2-1.1-20.8-3.5
				C41,347,41,344.9,41,344.9z M44.7,326.5c1.6-0.3,3.2-0.6,4.7-0.6c0.8,0,1.6,0.1,2.4,0.3c0.6,0.2,1.3,0.6,1.7,0.9s0.7,0.8,0.9,1.4
				c0.2,0.5,0.3,0.9,0.5,1.4c0.1,0.5,0.1,0.9,0.1,1.4v11.3h-3.8v-10.8c0-0.3-0.1-0.8-0.1-1c0-0.3-0.1-0.6-0.3-0.8
				c-0.2-0.3-0.5-0.6-0.8-0.7c-0.6-0.2-1-0.2-1.6-0.2c-1.1,0-2.4,0.1-3.5,0.3v13.2H41v-23.9l3.7-0.2V326.5z M68,329l-2.5,0.1
				l-1.6,0.2c-0.3,0.1-0.7,0.3-0.9,0.6c-0.2,0.2-0.5,0.5-0.5,0.8c-0.1,0.3-0.1,0.8-0.1,1.1v10.8h-3.8v-11.1c-0.1-1.3,0.2-2.4,1-3.3
				c0.8-0.8,1.8-1.4,2.9-1.6c1.8-0.5,3.7-0.6,5.6-0.6v3L68,329L68,329z"/>
                            <path d="M75.8,315.5c-3.2,0-5.8,2.6-5.8,5.8c0,3.2,2.6,5.8,5.8,5.8c3.2,0,5.8-2.6,5.8-5.8c0,0,0,0,0-0.1
				C81.6,318.2,79,315.5,75.8,315.5L75.8,315.5z M75.8,326.2c-2.6,0-4.8-2.2-4.8-4.8s2.2-4.8,4.8-4.8c2.6,0,4.8,2.2,4.8,4.8l0,0
				C80.6,324,78.4,326.2,75.8,326.2z"/>
                            <polygon points="77.6,323.1 77.6,318.3 72.9,320.1 72.9,321.3 74.4,320.7 74.4,324.2 			" />
                        </g>
                        <line style={styles.st0} x1="91.5" y1="333" x2="289" y2="333" />
                        <circle cx="304" cy="333" r="5" />
                        <rect style={styles.st0} x="40.5" y="315.5" width="42.7" height="35.4" />
                    </g>
                </a>
                <a id="rbb" xlinkHref="https://www.rbb-online.de/fernsehen/index.html" title="Rundfunk Berlin-Brandenburg" alt="Rundfunk Berlin-Brandenburg" target="_blank" rel="noopener noreferrer">
                    <path className="land" d="M542.4,129.2c1,2.5,1.8,4.4,2.3,5.2l-3.5,4.6c1.6,3.6-0.2,10.4-2.1,13.4l-6.6,4.7l0.3,9.5c5.6,0.4,13.7,11.7,21.1,15.6
		l0.6,8.5l-2.9,5.7l1.2,7.4l5.5,5c1,5.9,0.2,8.4,4.7,12.6c-0.3,11.6,1.3,9.2-5.5,18.3c2.7,3.3,7.4,10.7,7.4,16.6l-4.4,2.8l-5.6-0.2
		l-7.4,3.8l-9.3-0.1l-2.3,4.9l-2.4,6.2l-3-1.1l-7,1.6l-10.3,1.1l-5.2-3.7l-3-1.8l-2.1,0.2l-2.8,5.1l-3.5-2.5l-1.1-3.6l-3.5-7
		l0.1-1.4l-4.4-3.1l6.3-5.6l-1.5-8.7l0.8-3.3l-2.9-0.3l-3.5-2.3l-5-2.5l-6.2-3.4l-7.4,1l-2.3-2.3l-4.4-0.1l-8.1-6.6l1.6-4.7
		l-0.8-3.6l1.3-6.9l-0.7-2.5l1.2-7.2l-2-4l-4.1-0.4l-0.9-2l2.6-7.5l-1.7-3l-1.7-5.3l2.1-4.9l-2.4-5.7l-8.3,1.2l-5.7-1.4l-1.5-3.5
		l-4-2.8l-3.1-3.3l-4.2-1l-4.3-1.9l-5.1,0.9l-2.5-5.1l4.5,1.3l2.8-2.6l3.7,1.6l2.5-1.7l-0.1-5.7l5.5-2.3l2,2.6l10.8-6.1l0.3-3.7
		l7.1-0.8l4.3,2.1l6.5,6.7l6.2-0.2l4.2,2.6l3.3,1.9l4.7,0.3l3.1,0.9l6.1-4.7c0,0,1.5-1.4,1.7-1.4s6.9-2.4,6.9-2.4l2.7,2l4.8-3
		l1.3-8.6l6-2.8l2.4-4.1l4.3-0.3l-2.5-3.9l2.1-0.6l3.9,2.3l0.1,7l9.9-1.5l3.3,0.7l-0.1,4.5l-4.2,5c0,0,5.6,2.2,5.8,2.1
		c0.2-0.1,4.4-4.7,4.8-4.9C540.4,130.9,542.4,129.2,542.4,129.2L542.4,129.2L542.4,129.2z"/>
                    <path className="border" d="M487.9,200.5c-0.2-0.5,0.4-5.7,0.4-5.7l1.8-1.5l-1.5-5l3.2-1.8l3.2-2.3l0.1-2.2l4.4,1.2l3.5-3l2.4,1.8l1.2,4.8l1.5,2.8
		l3.7,0.5l1.1,4.1l-2.3,1.3l5.4,0.4l1.7,3l-4.2,4.4l-5.9-0.4l-3.2-2.5l-3.5,1.9l-6-0.4l-4.6-0.5C490.2,201.1,488,200.9,487.9,200.5
		L487.9,200.5z"/>
                    <g className="pfad">
                        <g className="logo">
                            <path d="M666.8,190.9l-5.5,25.6h-53.1v-25.6H666.8z M625.5,200.9c-5.1,0.1-8.9,0.8-11.5,2.1v7.7h2.3v-6.2
				c2.3-0.9,5.5-1.2,9.3-1.3L625.5,200.9L625.5,200.9z M630,196.5h-2.2v13.9c2.1,0.3,4.2,0.5,6.4,0.5c3.2,0,5.4-0.4,6.6-1.2
				c1.2-0.8,0.6-1.9,0.6-3.8c0.1-1.2-0.1-2.4-0.6-3.6c-2.3-1.1-4.8-1.5-7.3-1.4c-1.2,0-2.3,0.1-3.6,0.2L630,196.5L630,196.5z
				 M630,203.4l3.6-0.2c1.8,0,3.6,0.2,5.5,0.7c0.1,0.8,0.2,1.4,0.2,2.2c0,0.8-0.1,1.2-0.2,2.1c-1.6,0.4-3.2,0.5-4.8,0.5l-4.2-0.2
				V203.4z M645.8,196.5h-2.2v13.9c2.1,0.3,4.2,0.5,6.4,0.5c3.1,0,5.4-0.4,6.6-1.2c0.4-1.2,0.6-2.5,0.5-3.8c0.1-1.2-0.1-2.4-0.5-3.6
				c-2.3-1.1-4.8-1.5-7.3-1.4c-1.2,0-2.3,0.1-3.6,0.2L645.8,196.5L645.8,196.5z M645.8,203.4l3.6-0.2c1.8,0,3.6,0.2,5.5,0.7
				c0.1,0.8,0.2,1.4,0.2,2.2c0,0.8-0.1,1.2-0.2,2.1c-1.6,0.4-3.2,0.5-4.8,0.5l-4.2-0.2V203.4z"/>
                            <path d="M674.5,188c-3.3,0-5.9,2.6-5.9,5.9s2.6,5.9,5.9,5.9c3.3,0,5.9-2.6,5.9-5.9v-0.1C680.3,190.6,677.7,188,674.5,188z
				 M674.5,198.6c-2.7,0-4.9-2.1-4.9-4.8s2.1-4.9,4.8-4.9c2.7,0,4.9,2.1,4.9,4.8v0.1C679.3,196.4,677.3,198.5,674.5,198.6
				C674.6,198.6,674.6,198.6,674.5,198.6z"/>
                            <polygon points="676.4,195.6 676.4,190.7 671.6,192.5 671.6,193.6 673.2,193.1 673.2,196.7 			" />
                        </g>
                        <line style={styles.st0} x1="535" y1="204" x2="600.1" y2="204" />
                        <circle cx="524" cy="204" r="5" />
                        <rect style={styles.st0} x="608.2" y="187.8" width="72.1" height="28.6" />
                    </g>
                </a>
                <a id="mdr" xlinkHref="http://www.mdr.de/tv/index.html" title="Mitteldeutscher Rundfunk" alt="Mitteldeutscher Rundfunk" target="_blank" rel="noopener noreferrer">
                    <path className="land" d="M351.8,338.9c-0.1,0,0.2-0.3,0.2-0.3L351.8,338.9z M438.7,344.7l-13.7,3l-6.4-2.3l-1.3-5.4l-8.9,1l1.1,11.1l-3.9,3.9
		l-1.8-7.5l-5,1.5l-9.3-2.4l-6.3,3.9l5.6,6.6l-5.8,2.4l-5.4-1.9l-2.1-7.6l-7.1-3.7l-9.1-9.8l-5.1-1.3l-2.3,2.6l0.7-10.5
		c0,0-3.9,0.4-4.3,0.4c-0.5,0-3.4,1.8-3.4,1.8l3.4-14.8l3.8-1.7l-3.2-3.9l1.4-2.7l4.1-5.4l5,0.7l-1.4-6v-5l3.6,0.6l-2.9-3.6
		l-8.1-6.6l-1.8-6.5l0.9-0.1l8.3-3.8l5-4l5.9-1.1l0.8-5l4.7-1l1.2,3.5l5.3-1.5l2.4-0.1l-1.1-3.1l1.1-2.8l-0.4-4.9l-4.9-6.6l3.7-5.6
		l-0.2-5.4l-2.7-4.2l12.3-0.5l6.2-0.4l-0.1-3.5l4.7-4.4l-0.7-3.7l1.2-3.1l-3.1-6.6l0.6-3.5l-4.2-6l0.4-10l-1.4-1.4l-7-9.9l1.2-6.3
		l5-0.4l4.7-4.5l7.9,0.3l2.6,1.7l8.7-5.2l1.1-4.1l3-3l3.3,0.8l3.1,3.3l4,2.8l1.5,3.5l5.7,1.4l8.3-1.2l2.4,5.7l-2.1,4.9l1.7,5.3
		l1.7,3l-2.6,7.5l0.9,2l4.1,0.4l2,4l-1.2,7.2l0.7,2.5l-1.3,6.9l0.8,3.6l-1.6,4.7l8.1,6.6l4.4,0.1l2.3,2.3l7.4-1l6.2,3.4l5,2.5
		l3.5,2.3l2.9,0.3l-0.8,3.3l1.5,8.7l-5.7,5.1l3.9,3.3l-0.1,1.4l3.5,7l1.1,3.6l3.5,2.5l2.8-5.1l2.1-0.2l3,1.8l5.2,3.7l10.3-1.1l7-1.6
		l3,1.1l2.4-6.2l2.3-4.9l9.3,0.1l7.4-3.8l5.6,0.2l4.4-2.5c-0.1,1.5-0.4,2.8-1.2,4c3.5,1.9,6.9,4,10.1,6.3l0.5,6.9l3.7,2.9
		c0.1,0.8,0.9,7.9,0.9,8.2c-0.1,0.8-2.8,6.2-3.2,7.4c-0.7,2.3-0.3,5.4-1.1,7.7c-1.5,4.1-4.2,8.5-6.1,12.4l-6.8-1.6l1-7.1l-3,2.2
		l-1.3-1.5l1.7-4.1l-4-3.8l-4.3,1.3l-4.9-1.9l-2.3,3.3c1.1,2.4,4.8,5,7.4,5.4l-1.1,3.3c-5.8-0.4-14.3,7.1-21.3,8.6l-2.7,4
		c-4.6,0.5-9.2,1.3-13.7,2.1l-0.7,5.2l-3.3,2.6l-3.8-2.2l-1.9,3.4l-3.5,0.4l-2.9,5.8l-6.7-0.1l-5,5.7l-6.1-1.7l-4,3.3l-6.7,0.6
		l-8.6,10.7l0.2,4.1l-2.6,2.7c-0.1-3.6-2.3-9-4.7-11.7l-3.6,0.4l-4.9-2l-5.4-5.2L438.7,344.7z"/>
                    <path className="border" d="M454,296.4l-2.3-13.2l0.2-4.6l0.2-12.6l10.2-5.6l6.1,0.7l5.1-3.8l6.3-1l5.4,0.8l0,0l2.5,0.7 M438.7,344.7L438.7,344.7
		l-0.2-5.9l-2.4-2.9l7.3-7l1.8,2.3l-1.6,4l6,0.2l-0.3-2.9V330l3.3,0.1l5.1-3.5l-4.8-4.8l1.7-5.6l6.7-2.3l4.2-3.1l7.4-2.5l-2.6-6.4
		l-3.6,0.3l-4.4-4.8L454,296l1.1,6l-2.5,3l-5.7-0.9l-5-0.4l-6.2-4.4l-8-2.2l-7.5,0.5l-0.9-4.2l-4.1-4.4l4.7-4.4l-4.2-8.1l-5-1.5
		l-11.2-0.5l-3.6-0.4l-1.6-7.6l-2.1-4.4l0.8-3.6l-3.8-0.4l-5.7-1.3"/>
                    <g className="pfad">
                        <g className="logo">
                            <path d="M624.7,293.7c0.3-0.3,0.6-0.5,0.8-0.8l1.4-0.9c0.6-0.4,1.2-0.7,1.9-0.8c0.8-0.2,1.7-0.4,2.5-0.3c0.9,0,1.9,0.2,2.7,0.8
				c0.8,0.5,1.5,1.1,2,1.9c0.2,0.5,0.4,0.9,0.6,1.4c0.1,0.6,0.2,1.2,0.2,1.8v3.6h-3.6V297c0-0.3-0.1-0.7-0.1-0.8s-0.1-0.4-0.2-0.7
				c-0.2-0.4-0.5-0.8-0.8-1c-0.5-0.2-0.9-0.3-1.4-0.3c-0.4,0-0.8,0.1-1.2,0.3l-1.1,0.6c-0.4,0.2-0.8,0.4-1,0.8l-0.8,0.8l-1,0.9
				l-0.9,1c-0.4,0.3-0.7,0.6-0.9,0.9l-0.8,0.8c-0.1,0.1-0.2,0.2-0.3,0.1c-0.1-0.1-0.1-0.1-0.1-0.3v-3.2c0-0.8-0.2-1.4-0.8-2
				c-0.5-0.5-1.1-0.8-1.8-0.8c-0.5,0-0.9,0.1-1.3,0.3c-0.5,0.2-0.8,0.4-1.2,0.7l-1.2,1.1l-1.3,1.2l-1,1.1l-1,1.1
				c-0.5,0.4-0.8,0.8-1.2,1.2c-0.4,0.4-0.8,0.8-1.2,1.2s-0.2,0.2-0.3,0.2c-0.2,0-0.1-0.2-0.1-0.3v-6.8c0-0.3-0.1-0.7-0.3-0.8
				c-0.2-0.3-0.5-0.3-0.9-0.3h-2.4v-2.7h5.7c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.5,0.8,0.4,1.2v1.5l0.7-0.6c0.5-0.5,0.9-0.8,1.5-1.2
				c0.7-0.5,1.4-0.8,2.2-1.1c0.8-0.3,1.6-0.5,2.5-0.5c0.7,0,1.3,0.1,2,0.3c0.5,0.1,0.9,0.4,1.3,0.8c0.3,0.3,0.6,0.6,0.8,0.9
				C624.4,293.1,624.6,293.3,624.7,293.7z M648.2,290.8h1.4l1.6,0.4c0.6,0.2,1,0.5,1.6,0.8c0.6,0.4,1,0.8,1.5,1.3v-7.7
				c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.3-0.6-0.4-0.9-0.3h-2.3v-2.6h5.7c0.8-0.1,1.4,0.5,1.5,1.1c0,0.1,0,0.2,0,0.4v16.6
				c0,0.2,0,0.3-0.2,0.3c-0.2,0.1-0.2,0-0.3-0.2c-0.4-0.2-0.7-0.5-0.9-0.8l-1.2-1l-1.2-1l-1.2-0.8l-0.8-0.8l-1.1-0.8l-1.3-0.7
				c-0.5-0.2-1-0.3-1.6-0.3c-0.8,0-1.4,0.2-2.1,0.5c-0.6,0.2-1.1,0.6-1.6,1.1c-0.5,0.5-0.8,1-1.1,1.7c-0.2,0.6-0.4,1.2-0.3,1.9
				c0,0.5,0,1,0.2,1.5c0.1,0.5,0.2,0.9,0.4,1.3c0.1,0.4,0.3,0.8,0.6,1.1l0.6,0.8h-4.1l-0.5-0.8c-0.2-0.3-0.3-0.7-0.4-1
				c-0.2-0.5-0.3-0.8-0.3-1.3c-0.1-0.6-0.1-1-0.1-1.6c0-0.9,0.2-1.8,0.5-2.6c0.4-1,0.9-1.9,1.7-2.7
				C643.3,291.8,645.6,290.8,648.2,290.8z M667.5,294.1l0.7-0.6l0.8-0.6l0.8-0.7l0.8-0.5l1-0.5l0.9-0.4l1.2-0.2h1.6v3.2
				c-1.1,0.1-2.2,0.4-3.1,0.9c-1.3,0.8-2.6,1.8-3.7,2.9c-0.4,0.3-0.7,0.7-1,1l-1.1,1.1c-0.4,0.3-0.7,0.7-1,1l-0.8,0.8
				c-0.2,0.1-0.3,0.2-0.3,0.1c-0.1-0.1-0.2-0.1-0.2-0.3v-6.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.2-0.6-0.4-0.9-0.3h-2.4V291h5.7
				c0.4,0,0.8,0.1,1.1,0.4c0.3,0.4,0.4,0.8,0.4,1.2L667.5,294.1L667.5,294.1L667.5,294.1z"/>
                            <path d="M683,278.9c-3.3,0-5.9,2.6-5.9,5.9c0,3.3,2.6,5.9,5.9,5.9s5.9-2.6,5.9-5.9l0,0C688.8,281.5,686.2,278.9,683,278.9z
				 M683,289.7c-2.7,0-4.9-2.1-5-4.8c0-2.7,2.1-4.9,4.8-5c2.7,0,4.9,2.1,5,4.8c0,0.1,0,0.1,0,0.2C687.8,287.5,685.6,289.7,683,289.7
				z"/>
                            <polygon points="684.9,286.5 684.9,281.7 680.1,283.4 680.1,284.7 681.6,284.1 681.6,287.8 			" />
                        </g>
                        <line style={styles.st0} x1="511" y1="292" x2="588" y2="292" />
                        <circle cx="494" cy="292" r="5" />
                        <rect style={styles.st0} x="606.3" y="278.9" width="82.6" height="25" />
                    </g>
                </a>
                <a id="br" xlinkHref="http://www.br.de/br-fernsehen/index.html" title="Bayrischer Rundfunk" alt="Bayrischer Rundfunk" target="_blank" rel="noopener noreferrer">
                    <path className="land" d="M444.3,349.9l-5.3-5.2l-14,3l-6.4-2.3l-1.3-5.4l-8.9,1l1.1,11.1l-3.9,3.9l-1.8-7.5l-5,1.5l-9.3-2.4l-6.3,3.9l5.6,6.6
		l-5.8,2.4l-5.4-1.9l-2.1-7.6l-7.1-3.7l-9.1-9.8l-5.1-1.3l-2.3,2.6l-6.7,7.6l-5.4-1.1l-2.6,0.7l-0.4,6.9l-4.8,5.3l-6.2-0.2l0.5,7.9
		l-1.7,3.2l-5.2-3.6l-7.8,1.8l-2.9-1.3l-5.5,1.7l3.4,5.6l-2.8,2.8l0.6,10.1l4.6,8.1l-4,7.9l1.7,4.4l5.9-0.2l2.9-4.9l4.1,0.2l2.4-3.4
		l-1.7-3.4l-4.8-0.4c0,0,3.5-3.2,4-3.2c0.5,0.1,7.6-1.6,8.3-0.9c0.6,0.8,5.9,0.6,5.9,0.6l-1,6.6l3.9-1.4l3.1-0.7l2.6,8l-1.1,5
		l4.4-2.3l0.5,7.2l4.3-0.1c0,0,0.1-5.5,0.4-5.4s4.3,5.2,4.3,5.2l-1.2,3.9l1.5,4.3l-1.1,4.3l0.7,3.6l0.3,5.4l5.6,4.3l-1.5,2.8
		L370,453l0.2,9.9l-0.9,4.8l2.7,2.1l-5.3,3.8l-4.9-2.3l0.2,3.9l1.3,4.7l-3.6,5.1l-9.2,2.8l-4.4,4.5l2,5.2l2.8,5.8l1.1,8.5l2,6.8
		l-2.4,5.8l-0.3,6.4l1.4,3.2l-0.5,3.2l-0.1,5.7l-2.6,3.6l-2.7-2.7l-4.2,1.1l-4.3-1l-4.7,4.4l-5.8,2.5l5.1,2.8l3-2.9l2.6,3l6.8,1
		l5.5,5.7l0.9,7.3l6.1-1.2l-2.6,7.2l3.6,0.4l11.3-12.3l-2.4-5.2l0.9-3.8l4.1,2.7c1.4-0.2,3.2-1.3,4.1-2.3l2.7,0.6l1.8,2.3
		c2.3,0.5,5.6,0.2,7.8-0.7c0.5,3,3.8,7.6,5.7,9.7l6.1,0.2l4.3-2.4l2.7,1.9l6-3.2l2.7,0.1l0.1-4.3l6.9-1.3l2.4-5.1l10.1,0.4
		c2.9-2.8,10.1-3.1,13.7-1.8l3.9-1.6c-3.5-4.8-0.2-4.9,3.4-8.4l1.2,2.2l-1.8,2.2l2.3,0.9l7.5-1.3l1.5,3.6l4.9,0.5l2.8-3.8l6.1-1.1
		l2.3,2.1l-2.2,1.9l3.4,2.4l-1.3,3.8l7.8,6.1l5.2-0.7l0.5-7.5l2.4-3.5l-4-5.4l-5-0.9l3.3-6.8c-3.4-6.1-8.1-13-13.4-17.6l-0.1-4.9
		c5.2-3.5,11.2-8.5,16.8-11.3c0.2-0.1,7.7-1.2,8.4-1.3l7.5-6.4c0.6-2.4,1.5-10-0.5-12l4.4-3.8c3.1,0.6,6.8,3,9,5.1
		c0.7-1.3,3-4.2,4.6-4.5c-0.1-5.2-0.1-11.4,1.3-16.4l-4.9-6.1l-2.9,0.1l-5.2-6.4l-3.9,1.2l-5-1.8l-0.1-4.3l-5.7-4.7l-4.5,0.1
		c-3.5-5.7-7.5-11.2-11.6-16.4h-3.4l-1.2,1.2c-2.1-3.1-5.6-6.6-9-8.3c0.1-5.2-7.9-18.4-12.4-21.7c2.8-3.4,5.4-8.1,6.7-12.4l-3.6-2.4
		l-0.5-3.2l-3.2-0.2c-1.4-1.3-4.1-3.6-6-4.3l-2.6-10.8l-5-3l1.3-5.5L444.3,349.9L444.3,349.9z"/>
                    <g className="pfad">
                        <g className="logo">
                            <path d="M623.5,459.3c1.5,0.3,2.9,1,3.9,2.1c0.9,1.1,1.5,2.5,1.4,3.9c0.2,2.5-1,4.8-3,6.2c-2.1,1.5-5,2.1-8.8,2.1h-6.1V447h7.4
				c1.5,0,2.8,0.2,4.2,0.5c1,0.3,2,0.8,2.7,1.5c0.6,0.5,1.2,1.3,1.5,2.1c0.3,0.7,0.5,1.5,0.5,2.4c0,1.2-0.3,2.3-0.9,3.4
				C625.5,457.9,624.5,458.7,623.5,459.3z M618.2,469.9c1.5,0.1,3.2-0.3,4.5-1.1c1.1-0.7,1.6-1.9,1.5-3.2c0.1-1.5-0.5-2.8-1.5-3.8
				c-0.9-0.9-2.6-1.5-5-1.8v-0.6c1.3-0.2,2.5-0.7,3.5-1.5c0.8-0.9,1.3-2.2,1.2-3.5c0-0.5-0.1-1.1-0.3-1.5c-0.2-0.5-0.5-0.9-1-1.3
				c-0.5-0.5-1.1-0.7-1.6-0.9c-0.7-0.3-1.5-0.4-2.3-0.3h-2.1V470L618.2,469.9L618.2,469.9z M635.5,460.6l9.4,13h-5.4
				c-3.9-5.7-6.4-9.4-7.6-11.1c-0.6-1-1.5-1.8-2.6-2.4v-0.5c2.5-0.3,4.3-0.9,5.4-1.6c1-0.8,1.6-2,1.5-3.4c0.1-1.2-0.5-2.4-1.5-3.2
				c-1.2-0.8-2.6-1.3-4.1-1.2h-1.6c-0.3-0.6-0.6-1.3-1-1.8c-0.4-0.5-0.8-1-1.4-1.4v-0.3h4.2c1.5,0,3.2,0.2,4.6,0.5
				c1.1,0.3,2.2,0.8,3.1,1.5c0.7,0.5,1.3,1.3,1.6,2.1c0.4,0.8,0.5,1.6,0.5,2.5c0,1.5-0.5,3.1-1.5,4.3
				C638.3,459.1,637,460,635.5,460.6z"/>
                            <path d="M649.5,444.1c-3.3,0-5.9,2.6-5.9,5.9c0,3.3,2.6,5.9,5.9,5.9c3.3,0,5.9-2.6,5.9-5.9V450
				C655.5,446.8,652.8,444.1,649.5,444.1z M649.5,455c-2.6,0-4.8-2.2-4.8-4.8V450c0-2.6,2.2-4.8,4.8-4.8s4.8,2.1,4.9,4.7v0.1
				C654.5,452.8,652.3,455,649.5,455L649.5,455z"/>
                            <polygon points="651.5,451.9 651.5,447 646.6,448.8 646.6,450 648.2,449.3 648.2,453 			" />
                        </g>
                        <line style={styles.st0} x1="437" y1="460" x2="597.8" y2="460" />
                        <circle cx="422" cy="460" r="5" />
                        <rect style={styles.st0} x="610.9" y="444.3" width="44.6" height="30.4" />
                    </g>
                </a>
            </svg>

            <ul className="intern-transparenz">
                <li>
                    <a href="http://www.br.de/br-fernsehen/index.html" target="_blank" className="content" rel="noopener noreferrer">
                        <span>BR</span>
                        Bayerischer Rundfunk
                    </a>
                </li>
                <li>
                    <a href="https://www.hr-fernsehen.de/" target="_blank" className="content" rel="noopener noreferrer">
                        <span>HR</span>
                        Hessischer Rundfunk
                    </a>
                </li>
                <li>
                    <a href="http://www.mdr.de/tv/index.html" target="_blank" className="content" rel="noopener noreferrer">
                        <span>MDR</span>
                        Mitteldeutscher Rundfunk
                    </a>
                </li>
                <li>
                    <a href="http://www.ndr.de/fernsehen/index.html" target="_blank" className=" content">
                        <span>NDR</span>
                        Norddeutscher Rundfunk
                    </a>
                </li>
                <li>
                    <a href="https://www.radiobremen.de/programm/index.html" target="_blank" className="content" rel="noopener noreferrer">
                        <span>RB</span>
                        Radio Bremen
                    </a>
                </li>
                <li>
                    <a href="https://www.rbb-online.de/fernsehen/index.html" target="_blank" className="content" rel="noopener noreferrer">
                        <span>RBB</span>
                        Rundfunk Berlin-Brandenburg
                    </a>
                </li>
                <li>
                    <a href="https://www.sr.de/sr/home/fernsehen/index.html" target="_blank" className="content" rel="noopener noreferrer">
                        <span>SR</span>
                        Saarländischer Rundfunk
                    </a>
                </li>
                <li>
                    <a href="https://www.swrfernsehen.de/index.html" target="_blank" className="content" rel="noopener noreferrer">
                        <span>SWR</span>
                        Südwestrundfunk
                    </a>
                </li>
                <li>
                    <a href="http://www1.wdr.de/fernsehen/startseite/index.html" target="_blank" className="content" rel="noopener noreferrer">
                        <span>WDR</span>
                        Westdeutscher Rundfunk
                    </a>
                </li>
            </ul>
        </StyledChannelMap>
    );
}