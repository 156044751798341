import React from 'react';

import { Row, Column, Header } from '@ard-online/component-styleguide';

import { InteractiveMap } from '../styles';

const interactiveMap = require("../images/ard-kka_interaktive_grafik.jpg");
const interactiveMapRetina = require("../images/ard-kka_interaktive_grafik@2x.jpg");
const interactiveMapMobile = require("../images/ard-kka_interaktive_grafik_mobile.jpg");
const logo = require("../images/logo_ard-ci.svg");
const ardAllYours = require("../images/logo_ard-all-yours.svg");

export default function HomeSection() {
  return (
    <section id="home">
      <Row>
        <Column all={12} s={6} className="center-vertical">
          <h2>Explore the ARD: Germany's largest public broadcasting network</h2>
          <p>Connect to the ARD channel network if you wish to</p>
          <ul>
            <li>increase the attractiveness of your channel portfolio</li>
            <li>reach a wider audience with the right programme for every taste</li>
            <li>stand out from your competitors</li>
          </ul>
          <p>Choose your perfect German programme package from our bouquet of 16 TV stations and more than 60 radio stations: Starting with one of the most popular TV channels in Germany, <nobr>Das Erste</nobr>, through all regional ARD programmes to several special interest channels.</p>
          <p>ARD Channels International is licensing the ARD channels to networks all over Europe (except Germany).</p>
          <p><img className="allYours" src={ardAllYours} alt="All yours. ARD"/></p>
        </Column>
        <Column all={12} s={6}>
        <InteractiveMap>
          <img className="mobile" src={interactiveMapMobile}/>
          <img className="standard" src={interactiveMap} srcset={`
            ${interactiveMapRetina} 2x
          `}/>
          <ul>
            <li id="im-tile2" className="tile">
              <a href="javascript:void(0);"><span>Independence</span></a>
              <div className="tooltip">
                <div className="inner">
                  ARD is a public asset in Germany. It is therefore our responsibility to report on important political and social events in a balanced, impartial and comprehensive manner that reaches everyone. On top of this, ARD programmes are largely free of advertising. During prime time, your customers can enjoy our programmes in their entirety without commercial breaks.
                </div>
              </div>
            </li>
            <li id="im-tile1" className="tile">
              <a href="javascript:void(0);"><span>Excellence</span></a>
              <div className="tooltip">
                <div className="inner">
                  High-quality journalism and distinctive programming are our hallmarks. We help people to navigate today's information society by providing up-to-the-minute coverage, in-depth reporting and documentaries. More than 100 correspondents in 26 countries report directly from the location to provide a better understanding of global relations.
                </div>
              </div>
            </li>
            <li id="im-tile3" className="tile">
              <a href="javascript:void(0);"><span>Regionality</span></a>
              <div className="tooltip">
                <div className="inner">
                  ARD's nine regional broadcasters are deeply rooted in their respective region, providing viewers from abroad an authentic reflection of Germany - its local traditions, mindset, unique characteristics and everyday life.
                </div>
              </div>
            </li>
            <li id="im-tile4" className="tile">
              <a href="javascript:void(0);"><span>Reliability</span></a>
              <div className="tooltip">
                <div className="inner">
                  When times are difficult, as during the Covid-19 crisis, reliable news sources become even more important: With an average of almost 12 million viewers and 40% market share during prime time in Germany, the crisis brought "Tagesschau" new record ratings in 2020 - far ahead of all competing news programmes.
                </div>
              </div>
            </li>
            <li id="im-tile6" className="tile">
              <a href="javascript:void(0);"><span>Diversity</span></a>
              <div className="tooltip">
                <div className="inner">
                  ARD's content reflects the diversity of Germany and its people, responding to their needs and expectations, whether it be news or sports, satirical shows or comedy, film or radio drama, pop music or classical concerts. Not to forget the outstanding educational programmes with a wide range of cultural, artistic and scientific content. 
                </div>
              </div>
            </li>
            <li id="im-tile5" className="tile">
              <a href="javascript:void(0);"><span>Entertainment</span></a>
              <div className="tooltip">
                <div className="inner">
                  Award-winning fictional tv movies and series, exciting family entertainment and cheeky comedy shows: ARD produces and broadcasts first-rate entertainment at an outstanding level of quality.
                </div>
              </div>
            </li>
            
          </ul>
        </InteractiveMap>
      </Column>
      </Row>
    </section>
  );
}