import React from 'react';

import { Row, Column, Teaser } from '@ard-online/component-styleguide';

import { TeaserCont } from '../styles';

const logos = [
  {
    "type": "media",
    "href": null,
    "image": {
      "src": require('../images/logos/caiway_logo.png'),
      "alt": "Logo Caiway"
    }
  },
  {
    "type": "media",
    "href": null,
    "image": {
      "src": require('../images/logos/copydan_logo.svg'),
      "alt": "Logo Copydan"
    }
  },
  {
    "type": "media",
    "href": null,
    "image": {
      "src": require('../images/logos/delta_logo.svg'),
      "alt": "Logo Delta"
    }
  },
  {
    "type": "media",
    "href": null,
    "image": {
      "src": require('../images/logos/irf_logo.svg'),
      "alt": "Logo IRF"
    }
  },
  {
    "type": "media",
    "href": null,
    "image": {
      "src": require('../images/logos/lassen-ricard_logo.svg'),
      "alt": "Logo Lassen Ricard"
    }
  },
  {
    "type": "media",
    "href": null,
    "image": {
      "src": require('../images/logos/meo_logo.svg'),
      "alt": "Logo MEO"
    }
  },
  {
    "type": "media",
    "href": null,
    "image": {
      "src": require('../images/logos/orange_logo.svg'),
      "alt": "Logo Orange"
    }
  },
  {
    "type": "media",
    "href": null,
    "image": {
      "src": require('../images/logos/sfr_logo.svg'),
      "alt": "Logo SFR"
    }
  },
  {
    "type": "media",
    "href": null,
    "image": {
      "src": require('../images/logos/t-mobile_logo.png'),
      "alt": "Logo T-Mobile"
    }
  },
  {
    "type": "media",
    "href": null,
    "image": {
      "src": require('../images/logos/telenet_logo.svg'),
      "alt": "Logo Telenet"
    }
  },
  {
    "type": "media",
    "href": null,
    "image": {
      "src": require('../images/logos/vgr_logo.svg'),
      "alt": "Logo VGR"
    }
  },
  {
    "type": "media",
    "href": null,
    "image": {
      "src": require('../images/logos/ziggo_logo.svg'),
      "alt": "Logo Ziggo"
    }
  }
]

const teaser1 = {
	"type": "collection",
	"title": "One touch point for the ARD network",
  "image":{
    "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
  }
};
const teaser2 = {
	"type": "collection",
	"title": "Channel bouquet consulting",
  "image":{
    "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
  }
};
const teaser3 = {
	"type": "collection",
	"title": "Marketing support",
  "image":{
    "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
  }
};

export default function ServicesReferencesSection() {
  return (
    <section id="services-references">
      <Row>
        <Column>
          <h2>Services &amp; References</h2>
          <h3>ARD Channels International – Our Services</h3>
        </Column>
      </Row>
      <Row className="service-container" >
        <Column className="service" all={12} xs={12} s={6} m={4}>
          { /* <TeaserCont>
            <Teaser {...teaser1} textPosition="onImage" textAlignment="center" />
          </TeaserCont> */ }
          <h3>One touch point for the ARD</h3>
          <p>We operate as the coordinator of ARD’s broadcasters for programme retransmission in Europe.<br />Our activities are based on the European Satellite and Cable Directives governing coordination of copyright rules and rights related to copyright applicable to i.a. (cable) retransmission.</p>
        </Column>
        <Column className="service" all={12} xs={12} s={6} m={4}>
          { /* <TeaserCont>
            <Teaser {...teaser2} textPosition="onImage" textAlignment="center" />
          </TeaserCont> */ }
          <h3>Channel portfolio consulting</h3>
          <p>Our portfolio offers the whole variety of ARD networks' TV and radio channels. Benefit from our extensive experience in the field of programme retransmission in Europe when it comes to selecting the most suitable channels for your market.</p>
        </Column>
        <Column className="service" all={12} xs={12} s={6} m={4}>
          { /* <TeaserCont>
            <Teaser {...teaser3} textPosition="onImage" textAlignment="center" />
          </TeaserCont> */ }
          <h3>Marketing support</h3>
          <p>We support your marketing activities by providing all the relevant information and material about our channels.<br />This allows your customers to gain the best impression of our channels and the vast variety of content they offer.</p>
        </Column>
      </Row>
      <Row className="references">
        <Column>
          <h3>Selection of references</h3>
        </Column>
        { logos.map((logo, index) => 
          <Column key={index} all={4} xs={4} s={3} m={3}>
            <TeaserCont>
              <Teaser {...logo}/>
            </TeaserCont>
          </Column>
        )}
      </Row>
    </section>
  );
}