import React from 'react';

import {
  Link,
  useHistory
} from "react-router-dom";

import { Root, Content, Section, Footer, FooterRow, FooterColumn, Logos, StyledChannelLogo, StyledHeader } from './styles';

import { Toolbar } from '@ard-online/component-styleguide/dist/components/Toolbar/Toolbar';
import { ThemeProvider } from 'styled-components';
import { Row, Column } from '@ard-online/component-styleguide/dist/components/primitives/Grid';
import ardCiTheme from './ardCiTheme';

import ScrollToTop from './ScrollToTop';

export default function PrivacyPolicy() {

  const [value, setValue] = React.useState('en');

  const toolbarRef = React.createRef();

  const history = useHistory();
  const handleClick = () => history.push('/');

  const channelLinks = {
    br: "https://www.br.de/br-fernsehen/",
    hr: "https://www.hr-fernsehen.de/",
    mdr: "https://www.mdr.de/tv",
    ndr: "https://www.ndr.de/fernsehen/",
    radiobremen: "https://dein.radiobremen.de/",
    rbb: "https://www.rbb-online.de/fernsehen",
    sr: "https://www.sr.de/sr/home/fernsehen/index.html",
    swr: "https://www.swrfernsehen.de/",
    wdr: "https://www1.wdr.de/fernsehen",
    dw: "https://www.dw.com/",
    daserste: "https://www.daserste.de/",
    one: "https://www.ardmediathek.de/one/",
    tagesschau24: "https://www.tagesschau24.de/",
    alpha: "https://www.ardmediathek.de/alpha/",
    '3sat': "https://www.3sat.de",
    arte: "https://www.arte.de",
    kika: "https://www.kika.de/",
    phoenix: "https://www.phoenix.de/",
    funk: "https://www.funk.net/",
    deutschlandradio: "https://www.deutschlandradio.de/",
    zdf: "https://www.zdf.de/",
  }

  const toolbarData = {
    actionIconsRight: [
      { icon: "account", text: value },
    ],
    items: [
      { text: "back to main page", href: '#', onClick: handleClick },
    ],
    logoButton: {
      as: Link,
      type: 'text',
      text: "back to start page",
      onClick: handleClick
    },
    accountNavigationItems: [
      { text: "deutsch", href: '/de', onClick: (e) => { setValue(e.target.innerText.substring(0, 2)) }},
      { text: "english" }
    ],
  }

  return (
    <ThemeProvider theme={ardCiTheme}>
      <StyledHeader ref={toolbarRef} className="header">
        <Toolbar {...toolbarData} hasLogo hasCenteredLogo={false} fixed={true} />
      </StyledHeader>
      <main>
        <Root>
          <Row>
            <Column>
              <Content>
                <Section>
                  <section>
                    <Row>
                      <Column>
                        <h2>Privacy Policy</h2>
                                                
                        <p>In the following we would like to inform you in an understandable and concise way about the collection and processing of your data and about your rights in this regard.</p>
                        
                        <h3>I. The data controller responsible is</h3>
                        
                        <p>WDR mediagroup GmbH<br/>
                          Ludwigstrasse 11<br/>
                          50667 Cologne<br/>
                          Germany<br/>
                          E-mail: info@wdr-mediagroup.com</p>
                        
                        <h3>II.	Data Protection Officer (DPO)</h3>
                        
                        <p>WDR mediagroup GmbH<br/>
                          - Data Protection Officer (DPO) -<br/>
                          Ludwigstrasse 11<br/>
                          50667 Cologne<br/>
                          Germany<br/>
                          E-mail: datenschutz@wdr-mediagroup.com</p>

                        <h3>III. General information on data processing</h3>

                        <p><strong>1. Scope and purpose of processing personal data</strong></p>

                        <p>We process personal data</p>

                        <p>a) to fulfil contractual obligations (Art. 6 para. 1 lit. b GDPR)</p>

                        <p>Data will be processed for the fulfilment of contracts or during pre-contractual measures, which are carried out at your request.</p> 

                        <p>b) on the basis of a legitimate interest (Art. 6 para. 1 lit. f GDPR)</p>

                        <p>If necessary, your data will be processed beyond the aforementioned purposes to protect legitimate interests.</p>

                        <ul>
                          <li>Improvement and further development of the offer,</li>
                          <li>Measures for business management and further development,</li>
                          <li>Ensuring the IT security and functionality of the website,</li>
                          <li>Assertion of claims and defence in legal disputes,</li>
                          <li>Measures for building and plant security (e.g. access controls),</li>
                          <li>Measures to secure the domiciliary right,</li>
                          <li>Advertising or market and opinion research as far as you have not objected to the use.</li>
                        </ul>

                        <p>c) on the basis of your consent (Art. 6 para. 1 a GDPR)</p>

                        <p>If you have given us consent to the processing of personal data for certain purposes (e.g. newsletter dispatch), this consent is the legal basis for the processing. A given consent can be revoked at any time.</p>

                        <p>In addition, we process – to the extent necessary for the provision of our services – personal data that we permissibly obtain from publicly accessible sources (e.g. press, Internet) or that is transmitted to us by other companies of the WDR mediagroup GmbH or by other third parties.</p>

                        <p><strong>2. Data retention period and data deletion</strong></p>

                        <p>The personal data will be deleted or blocked as soon as the purpose of the storage no longer applies. Storage may take place beyond this if this has been provided for by law or if there is a need to continue storing the data for the conclusion or performance of a contract.</p>

                        <p><strong>3.	Sharing information (personal data) with third parties </strong></p>

                        <p>For the processing of your data we partly use specialized service providers. They receive only the personal data they need for their specific activities. The service providers are carefully selected and regularly checked by us. They process personal data only by order and strictly in accordance with our instructions on the basis of corresponding contracts for order processing.</p>

                        <p>Insofar as the service providers we commission to process personal data on our behalf and within the scope of our instructions as so-called processors pursuant to Art. 28 of the GDPR are located outside the EU/EEA, we shall ensure before transferring data to them that they guarantee an adequate level of data protection (e.g. adequacy decision of the EU Commission / conclusion of EU standard contractual clauses, etc.).</p>

                        <h3>IV.	Processing of personal data</h3>

                        <p><strong>1. Information collected automatically when you visit our website</strong></p>

                        <p>You can visit our website without providing any personal information. Whenever you access a website, the web server merely automatically saves a so-called server log file, which contains, for example, the name of the requested file, your IP address, the date and time of the access, the amount of data transferred and the requesting provider (log data) and documents the access. This log data includes:</p>
                        
                        <ul>
                          <li>Your IP address</li>
                          <li>Name of the website/file accessed</li>
                          <li>Date and time of retrieval</li>
                          <li>Amount of data transferred</li>
                          <li>Notification of successful retrieval</li>
                          <li>Browser type and browser version</li>
                          <li>Operating system used </li>
                          <li>End device used, incl. MAC address</li>
                          <li>Referrer URL (previously visited page)</li>
                        </ul>

                        <p>This information is evaluated solely for the purpose of ensuring error-free operation of the site as well as for improving our offer and the possibility of detecting and tracking attacks. It serves to safeguard our legitimate interests in the correct presentation of our offer, which outweigh our interests in the context of a balancing of interests in the sense of Art. 6 Para. 1 lit. f GDPR.</p>

                        <p><strong>2. Data you provide to us</strong></p>

                        <p>You can contact us at any time using the contact options provided (contact form, e-mail, telephone). The data you enter will be transmitted to us and stored in order to process your request.</p>

                        <p>The data is deleted as soon as it is no longer required to serve the purpose for which it was collected.</p>

                        <h3>V. Your rights</h3>

                        <p>You have, among other things, the right to request information about your personal data stored by us. In certain circumstances, you can also request the correction or deletion of your data. Furthermore, you have the right to restrict the processing of your data and the right to receive the data you have provided in a structured, commonly used and machine-readable format.</p>

                        <p>If data processing is based on your consent, you can revoke this consent at any time with effect for the future. If data processing is based on overriding legitimate interests, you can object to this data processing if this justifies your particular situation.</p>
                        
                        <p>Every data subject shall have the right to lodge a complaint with a supervisory authority if the data subject considers that there has been a breach of data protection regulations.</p>

                        <p>The supervisory authority responsible for us is the</p>

                        <p>Broadcasting Data Protection Officer<br/>
                        Stephan Schwarze<br/>
                        Kantstr. 71-73<br/>
                        04275 Leipzig<br/>
                        Germany</p>

                        <h3>VI.	Updates and changes to this Data Protection Declaration</h3>

                        <p>This data protection declaration is currently valid and has been updated in June 2023.</p>

                        <p>Due to the further development of our website and the services thereon or due to amended legal or regulatory requirements it may be necessary to amend this Data Protection Declaration. The current data protection declaration can be accessed here at any time.</p>
                      
                      </Column>
                    </Row>
                  </section>
                </Section>
              </Content>
            </Column>
          </Row>
        </Root>
      </main>
        <footer>
          <Footer>
            <FooterRow>
              <FooterColumn>
                <Row>
                  <Column>
                    <nav><ul >
                      <li><Link to="/imprint">Imprint</Link></li>
                      <li className="active"><strong>Privacy policy</strong></li>
                    </ul></nav>
                  </Column>
                </Row>
                <Row>
                  <Column p="0">
                    <Logos>
                      {Object.keys(channelLinks).map((channelKey, index) => 
                        <a key={index} href={channelLinks[channelKey]}><StyledChannelLogo key={channelKey} channel={channelKey} /></a>
                      )}
                    </Logos>
                  </Column>
                </Row>
              </FooterColumn>
            </FooterRow>
          </Footer>
        </footer>
        <ScrollToTop />
    </ThemeProvider>
  );

}