import { Row, Column } from '@ard-online/component-styleguide';
import React from 'react';

export default function RadioChannelsSection() {
  return (
    <section id="radio-channels">
      <Row>
        <Column>
          <h2>Radio</h2>
          <p>The ARD offers more than 60 <a href="https://www.ardaudiothek.de/sender">radio channels</a> produced by the regional broadcasting stations.</p>
          <p>Please contact us if you are interested in our radio channels.</p>
        </Column>
      </Row>
    </section>
  );
}