import React from 'react';

import { Row, Column, Teaser } from '@ard-online/component-styleguide';
import { TeaserCont } from '../styles';

import ChannelMap from '../ChannelMap';

const ardLogo = require('@ard-online/component-styleguide/dist/assets/images/logos/SVG/colored/ard.svg');
const dasErsteLogo = require('@ard-online/component-styleguide/dist/assets/images/logos/SVG/colored/daserste.svg');

const channels = {
  "das-erste": [
    {
      "type": "text",
      "title": "Das Erste",
      "text": <div className="text">
          <p>It is ARD’s flagship channel on German television, jointly operated by all nine ARD members. Generations of viewers have considered it to be the epitome of credibility and expertise.</p>
          <p>With around 50 million people tuning in every week, Das Erste is one of the most-watched TV channels in Germany and considered to provide the highest-quality programming on German television. </p>
          <p>Being Germany's preferred source of information it promotes an interest in politics and stimulates discussion. Its average annual programming output comprises about 42% informational and 38% fictional content.</p>
        </div>,
      "href": "https://www.daserste.de",
      "image": {
        "src": require('../logos/das-erste_vert.svg'),
        "alt": "Logo Das Erste"
      }
    },
  ],
  "regional": [
    {
      "type": "text",
      "title": "BR",
      "text": "Bavarian Broadcasting Corporation",
      "href": "https://www.br.de/br-fernsehen/",
      "image": {
        "src": require('@ard-online/component-styleguide/dist/assets/images/logos/SVG/colored/br.svg'),
        "alt": "Logo BR"
      }
    },
    {
      "type": "text",
      "title": "HR",
      "text": "Hessian Broadcasting Corporation",
      "href": "https://www.hr-fernsehen.de",
      "image": {
        "src": require('@ard-online/component-styleguide/dist/assets/images/logos/SVG/colored/hr.svg'),
        "alt": "Logo BR"
      }
    },
    {
      "type": "text",
      "title": "MDR",
      "text": "Central German Broadcasting Corporation",
      "href": "https://www.mdr.de/tv",
      "image": {
        "src": require('@ard-online/component-styleguide/dist/assets/images/logos/SVG/colored/mdr.svg'),
        "alt": "Logo MDR"
      }
    },
    {
      "type": "text",
      "title": "NDR",
      "text": "Northern German Broadcasting Corporation",
      "href": "https://www.ndr.de/fernsehen/",
      "image": {
        "src": require('@ard-online/component-styleguide/dist/assets/images/logos/SVG/colored/ndr.svg'),
        "alt": "Logo NDR"
      }
    },
    {
      "type": "text",
      "title": "Radio Bremen",
      "text": "Bremen Broadcasting Corporation",
      "href": "https://dein.radiobremen.de/",
      "image": {
        "src": require('@ard-online/component-styleguide/dist/assets/images/logos/SVG/colored/radiobremen.svg'),
        "alt": "Logo Radio Bremen"
      }
    },
    {
      "type": "text",
      "title": "RBB",
      "text": "Berlin-Brandenburg Broadcasting Corporation",
      "href": "https://www.rbb-online.de/fernsehen",
      "image": {
        "src": require('@ard-online/component-styleguide/dist/assets/images/logos/SVG/colored/rbb.svg'),
        "alt": "Logo RBB"
      }
    },
    {
      "type": "text",
      "title": "SR",
      "text": "Saarland Broadcasting Corporation",
      "href": "https://www.sr.de/sr/home/fernsehen/index.html",
      "image": {
        "src": require('@ard-online/component-styleguide/dist/assets/images/logos/SVG/colored/sr.svg'),
        "alt": "Logo SR"
      }
    },
    {
      "type": "text",
      "title": "SWR",
      "text": "South-western German Broadcasting Corporation",
      "href": "https://www.swrfernsehen.de",
      "image": {
        "src": require('@ard-online/component-styleguide/dist/assets/images/logos/SVG/colored/swr.svg'),
        "alt": "Logo SWR"
      }
    },
    {
      "type": "text",
      "title": "WDR",
      "text": "Western German Broadcasting Corporation",
      "href": "https://www1.wdr.de/fernsehen",
      "image": {
        "src": require('@ard-online/component-styleguide/dist/assets/images/logos/SVG/colored/wdr.svg'),
        "alt": "Logo WDR"
      }
    }
  ],
  "digital": [
    {
      "type": "text",
      "title": "ONE",
      "text": <div className="text"><p>Within the ARD family, ONE is a drama and entertainment channel with a young, fresh approach aimed at the 29- to 49-year-old target group. Of the programmes offered, which include film, entertainment and events, its drama series are most noteworthy and successful.</p></div>,
      "href": "https://www.ardmediathek.de/one/",
      "image": {
        "src": require('../logos/one.svg'),
        "alt": "Logo ONE"
      }
    },
    {
      "type": "text",
      "title": "tagesschau24",
      "text": <div className="text"><p>News channel tagesschau24 keeps viewers abreast of the latest political, economic, cultural, sports and science news. It also offers magazine programmes, talk shows, reports and documentaries. Tagesschau24 is developing new cross-media information formats while also continuing to drive technical innovation.</p></div>,
      "href": "https://www.tagesschau24.de/",
      "image": {
        "src": require('@ard-online/component-styleguide/dist/assets/images/logos/SVG/colored/tagesschau24.svg'),
        "alt": "Logo tagesschau24"
      }
    },
    {
      "type": "text",
      "title": "ARD-alpha",
      "text": <div className="text"><p>ARD-alpha is a unique channel focusing solely on education, combining the best science and knowledge formats of the ARD family. Not only adults appreciate ARD-alpha's content, but the family programmes are also popular with children. The channel provides explanations, helps to put information into the right context, and provides background information.</p></div>,
      "href": "https://www.ardmediathek.de/alpha/",
      "image": {
        "src": require('../logos/ard-alpha.svg'),
        "alt": "Logo ARD-alpha"
      }
    }
  ],
  "partnership": [
    {
      "type": "text",
      "title": "3sat",
      "text": <div className="text"><p>Culture, science and education are the ingredients of 3sat, produced jointly by ZDF, ARD, Austria’s ORF and Switzerland’s SRG. It provides viewers with a wide spectrum of cultural programmes ranging from everyday events to background topics, covering literature, film, art, satire, architecture and music.</p></div>,
      "href": "https://www.3sat.de/",
      "image": {
        "src": require('@ard-online/component-styleguide/dist/assets/images/logos/SVG/colored/dreiSat.svg'),
        "alt": "Logo 3sat"
      }
    },
    {
      "type": "text",
      "title": "KiKA",
      "text": <div className="text"><p>ARD and ZDF’s joint children's channel, KiKA, provides information, education, advice and entertainment for children. Its aim is that television should not only be fun but also educational.<br/>Its fresh, diverse and innovative content makes KiKA the most popular children’s channel.</p></div>,
      "href": "https://www.kika.de/",
      "image": {
        "src": require('@ard-online/component-styleguide/dist/assets/images/logos/SVG/colored/kika.svg'),
        "alt": "Logo KiKA"
      }
    },
    {
      "type": "text",
      "title": "phoenix",
      "text": <div className="text"><p>ARD and ZDF’s current-affairs and documentary channel places a particular emphasis on detailed, analytical and nuanced reporting. Live events are closely interwoven with documentaries, discussion programmes and digital media offerings. phoenix is also responsible for providing an authentic portrayal of how political institutions and democratic processes work.</p></div>,
      "href": "https://www.phoenix.de/",
      "image": {
        "src": require('../logos/phoenix.svg'),
        "alt": "Logo phoenix"
      }
    }
  ]
}

export default function TvChannelsSection() {

  const getClassName = (title) => title.replace(/\s+/g, '-').toLowerCase();

  const _jumpTo = (e) => {

    e.preventDefault();

    ((selector, yOffset = -96) => {
      selector = selector.current ? selector.current : selector;
      const y = selector.getBoundingClientRect().top + window.pageYOffset + yOffset;
    
      window.scrollTo({top: y, behavior: 'smooth'});
    })(document.querySelector(e.target.getAttribute('href')));

  }

  return (
    <section id="tv-channels">
      <Row>
        <Column>
          <h2>TV</h2>
          <p><strong>Connect to the <a href="https://ard.de" target="_blank" rel="noopener">ARD network</a> and benefit from the superb programme variety provided by 16 first-rate German TV channels.</strong></p>
          <p>ARD Channels International's channel portfolio includes the joint programme <a href="#flagship-channel" onClick={_jumpTo}>Das Erste</a>, the digital programmes <a href="#digital-channels" onClick={_jumpTo}>ONE</a>, <a href="#digital-channels" onClick={_jumpTo}>tagesschau24</a> and <a href="#digital-channels" onClick={_jumpTo}>ARD-alpha</a> as well as <a href="#regional-channels" onClick={_jumpTo}>ARD's Regional Channels</a>. On top of that, we can offer you our partnership channels <a href="#partnership-channels" onClick={_jumpTo}>3sat</a>, <a href="#partnership-channels" onClick={_jumpTo}>KiKA</a> and <a href="#partnership-channels" onClick={_jumpTo}>phoenix</a>.</p>
        </Column>
      </Row>
      <Row>
        <Column>
          <h3 id="flagship-channel">ARD's Flagship Channel</h3>
          <TeaserCont className="channel-list">
            { channels['das-erste'].map((channel, index) =>
              <Teaser id={channel.title} key={index} {...channel} textPosition="besideImage"/>
            )}
          </TeaserCont>
          <p className="inlineImages">By the way: Abroad, <a href="https://www.ard.de"><img className="inlineLogo" src={ardLogo} height="40" alt="ARD Logo" title="ARD"/></a> is often mistaken for <a href="https://www.daserste.de"><img className="inlineLogo" src={dasErsteLogo} height="54" alt="Das Erste Logo" title="Das Erste"/></a><br/>In reality, ARD is the umbrella organisation [Arbeitsgemeinschaft der öffentlich-rechtlichen Rundfunkanstalten der Bundesrepublik Deutschland] and Das Erste is its collaborative television channel.</p>
        </Column>
      </Row>
      <Row className="mt-2">
        <Column all={12} s={6}>
          <h3 id="regional-channels">ARD's Regional Channels</h3>
          <p><strong>Close to people and events</strong></p>
          <ul>
            <li>The regional channels operated by ARD’s nine broadcasters provide local information, services and entertainment programmes.</li>
            <li>Up-to-date and traditional, close to home and cosmopolitan, creative and reliable – diversity at its best.</li>
            <li>Every day, an average of 25 million viewers in Germany tune in to one of the regional channels.</li>
          </ul>
        </Column>
        <Column all={12} s={6}>
          <ChannelMap></ChannelMap>
        </Column>
      </Row>
      <Row className="regional-channels">
        { channels['regional'].map((channel, index) => 
          <Column key={index} all={6} xs={6} s={4} m={3}>
            <TeaserCont id={channel.title} className={ 'channelLogo ' + getClassName(channel.title)}>
              <Teaser {...channel} hoverEffect={'default'}/>
            </TeaserCont>
          </Column>
        )}
      </Row>
      <Row className="mt-2">
        <Column>
          <h3 id="digital-channels">ARD's Digital Channels</h3>
          <TeaserCont className="channel-list">
            { channels['digital'].map((channel, index) =>
              <div className={ 'channelLogo ' + getClassName(channel.title)}>
                <Teaser id={channel.title} key={index} {...channel} textPosition="besideImage"/>
              </div>
            )}
          </TeaserCont>
        </Column>
      </Row>
      <Row>
        <Column>
          <h3 id="partnership-channels">ARD's Partnership Channels</h3>
          <p><strong>The ARD produces programmes as part of a joint venture with other German and European public broadcasters.</strong></p>
          <TeaserCont className="channel-list">
            { channels['partnership'].map((channel, index) =>
              <div className={ 'channelLogo ' + getClassName(channel.title)}>
                <Teaser key={index} id={channel.title} className={ 'channelLogo ' + getClassName(channel.title)} {...channel} textPosition="besideImage"/>
              </div>
            )}
          </TeaserCont>
        </Column>
      </Row>
    </section>
  );
}