import { rgba, lighten, darken } from 'polished';
import { themeBreakpoints } from '@ard-online/component-styleguide/dist//components/primitives/Grid';
import LogoWhite from './images/logo_ard-ci_neg.svg';
import LogoColored from './images/logo_ard-ci.svg';

const colors = Object.freeze({
	color1: '#003480', // Primary
	color2: '#0062d8', // Secondary
	color3: '#001e50',
	color4: '#FFF',
	color5: '#FF6670',
	color6: '#e2e2e2',
	color7: '#000',
	color8: '#f00',
});

export default Object.freeze({
	id: 'ardCi',
	name: 'ARD Channels International', // Wird zur Anzeige bei Tooltips verwendet
	fontFamily: "'Thesis', sans-serif",
	primary: {
		text: colors.color4,
		base: colors.color1,
		light: lighten(0.15, colors.color1),
		dark: colors.color1,
	},
	secondary: {
		text: colors.color4,
		base: colors.color2,
		light: lighten(0.15, colors.color2),
		dark: darken(0.15, colors.color2),
	},
	square: {
		text: colors.color4,
		base: colors.color1,
	},
	text: colors.color1,
	// icon: ,
	focus: {
		text: colors.color4,
		background: colors.color2,
	},
	background: colors.color4,
	page: colors.color4,
	overlay: {
		text: colors.color4,
		background: rgba(colors.color1, 0.8),
	},
	teaser: {
		headline: colors.color1,
		subline: rgba(colors.color1, 0.78),
		text: colors.color1,
		icon: colors.color1,
		noImageBackground: `linear-gradient( 160deg, ${colors.color4}, ${colors.color3})`,
		background: 'transparent',
		hoverBackground: colors.color4,
		isPlayingBackground: colors.color4,
	},
	card: {
		text: colors.color1,
		background: colors.color4,
	},
	toolbar: {
		background: colors.color1,
		accent: colors.color2,
		text: colors.color4,
		logo: LogoWhite,
		logoDimensions: {
			width: 69,
			height: 38,
		},
		shadow: rgba(colors.color7, 0.8),
	},
	navigationpanel: {
		header: {
			logo: LogoColored,
			text: colors.color1,
			background: colors.color4,
		},
		text: colors.color1,
		active: colors.color2,
		separator: colors.color6,
		background: colors.color4,
		submenu: {
			background: colors.color4,
		},
		meta: {
			icon: colors.color4,
			background: colors.color1,
		},
	},
	title: {
		// Modulheadline
		text: colors.color1,
		background: 'transparent',
	},
	badge: {
		background: colors.color2,
		text: colors.color4,
	},
	shadow: {
		text: colors.color4,
		base: colors.color3,
		onImageTop: rgba(colors.color3, 0),
		onImage: rgba(colors.color3, 0.8),
	},
	swiper: {
		background: 'transparent',
		scrollbar: 'transparent',
		button: colors.color1,
		arrow: colors.color4,
		control: colors.color2,
		bullet: rgba(colors.color1, 0.5),
		bulletActive: colors.color2,
	},
	switch: {
		enabled: colors.color2,
		disabled: colors.color6,
	},
	progress: rgba(colors.color4, 0.9),
	live: {
		base: colors.color5,
		text: colors.color4,
	},
	duration: {
		text: colors.color4,
		background: rgba(colors.color1, 0.8),
	},
	banner: colors.color4,
	// countdown: linear-gradient(to bottom, rgba(colors.color2, 0.5) 0%,rgba(colors.color2, 0.8) 50%,rgba(colors.color1, 0.8) 50%,rgba(colors.color1, 0.5) 100%),
	modal: {
		text: colors.color3,
		background: colors.color4,
		overlay: 'transparent',
	},
	notification: {
		text: colors.color3,
		background: colors.color4,
	},
	gallery: {
		textPage: colors.color4,
		backgroundPage: 'transparent',
		textArticel: colors.color3,
		backgroundArticel: colors.color4,
	},
	search: {
		text: colors.color1,
		input: colors.color4,
		glass: colors.color1,
		logo: LogoColored,
		background: colors.color4,
	},
	guide: {
		navBar: colors.color1,
		pill: colors.color4,
		time: colors.color2,
	},
	loading: colors.color2,
	betaLabel: {
		text: colors.color4,
		base: colors.color2,
	},
	decor: {
		text: colors.color1,
		background: colors.color4,
	},
	error: { base: colors.color8 },
	...themeBreakpoints,
});
