import React, { useState } from 'react';

import {
  Link
} from "react-router-dom";

import { Root, Content, Section, Footer, FooterRow, FooterColumn, Logos, StyledChannelLogo, StyledHeader } from './styles';

import { Toolbar } from '@ard-online/component-styleguide/dist/components/Toolbar/Toolbar';
import { ThemeProvider } from 'styled-components';
import { Row, Column } from '@ard-online/component-styleguide/dist/components/primitives/Grid';

import ardCiTheme from './ardCiTheme';

import HomeSection from './sections/HomeSection';
import TvChannelsSection from './sections/TvChannelsSection';
import RadioChannelsSection from './sections/RadioChannelsSection';
import ServicesReferencesSection from './sections/ServicesReferencesSection';
import ContactSection from './sections/ContactSection';

import ScrollToTop from './ScrollToTop';

import { useScrollPosition } from '@n8tb1t/use-scroll-position';

export default function Layout() {

  var isScrolling = false;

  const tagesschau24Logo = require("./logos/tagesschau24.svg");

  useScrollPosition(({ prevPos, currPos }) => {

    if( !isScrolling ){

      let selector = sectionRefs[0].current ? sectionRefs[0].current : sectionRefs[0];
      let idx = 0;
      for (let ref of sectionRefs) {
        let tmpSelector = ref.current ? ref.current : ref;
        if (tmpSelector && tmpSelector.getBoundingClientRect) {
          //console.log(tmpSelector.getBoundingClientRect().top + tmpSelector.offsetHeight);
          if (tmpSelector.getBoundingClientRect().top + tmpSelector.offsetHeight > 96) {
            break;
          }
          selector = tmpSelector;
          idx++;
        }
      }

      if( toolbarData.items[idx] && ( toolbarData.items[idx].isActive === undefined || !toolbarData.items[idx].isActive ) ){
        //console.log('switch to', toolbarData.items[idx]);
        setToolbarData(prevToolbarData => {
          prevToolbarData.items = prevToolbarData.items.map((item, index) => {
            //console.log('switch ' + index + ' to: ', index === idx);
            item.isActive = index === idx;
            return item;
          });
          return { ...prevToolbarData }
        });
      }

    }

  }, [], undefined, true, 100)

  function _scrollTo(selector, yOffset = -96, mobile = false) {
    isScrolling = false;
    setShowMobileNav(prevState => false);
    selector = selector.current ? selector.current : selector;
    if (selector && selector.getBoundingClientRect) {
      const y = selector.getBoundingClientRect().top + window.pageYOffset + yOffset;
      console.log(y);
      isScrolling = !mobile ? true : false;
      window.scrollTo({ top: y, behavior: 'smooth' });
      setTimeout(() => {
        isScrolling = false;
        window.scrollTo({ top: window.scrollY+1 });
      }, 1000);
    }
    return false;
  }

  const [value, setValue] = React.useState('en');

  const [showMobileNav, setShowMobileNav] = useState(false);

  const [sectionRefs, setSectionRefs] = useState([...Array(5).keys()].map(i => React.createRef()));

  const channelLinks = {
    br: "https://www.br.de/br-fernsehen/",
    hr: "https://www.hr-fernsehen.de/",
    mdr: "https://www.mdr.de/tv",
    ndr: "https://www.ndr.de/fernsehen/",
    radiobremen: "https://dein.radiobremen.de/",
    rbb: "https://www.rbb-online.de/fernsehen",
    sr: "https://www.sr.de/sr/home/fernsehen/index.html",
    swr: "https://www.swrfernsehen.de/",
    wdr: "https://www1.wdr.de/fernsehen",
    daserste: "https://www.daserste.de/",
    one: "https://www.ardmediathek.de/one/",
    tagesschau24: "https://www.tagesschau24.de/",
    alpha: "https://www.ardmediathek.de/alpha/",
    '3sat': "https://www.3sat.de",
    kika: "https://www.kika.de/",
    phoenix: "https://www.phoenix.de/",
    deutschlandradio: "https://www.deutschlandradio.de/",
  }

  const [toolbarData, setToolbarData] = useState({
    hasControlledItems: true,
    actionIconsRight: [
      { icon: "account", text: value },
    ],
    items: [
      { text: "Home", href: "javascript:void(0);", isActive: true, onClick: () => _scrollTo(sectionRefs[0]) },
      { text: "TV", href: "javascript:void(0);", onClick: () => _scrollTo(sectionRefs[1]) },
      { text: "Radio", href: "javascript:void(0);", onClick: () => _scrollTo(sectionRefs[2]) },
      { text: "Services & References", href: "javascript:void(0);", onClick: () => _scrollTo(sectionRefs[3]) },
      { text: "Contact", href: "javascript:void(0);", onClick: () => _scrollTo(sectionRefs[4]) }
    ],
    logoButton: {
      as: Link,
      type: 'text',
      text: "zurück zum Anfang",
      onClick: () => _scrollTo(sectionRefs[0])
    },
    accountNavigationItems: [
      { text: "deutsch", href: '/de', onClick: (e) => { setValue(e.target.innerText.substring(0, 2)) }},
      { text: "english" }
    ],
  });

  return (
    <ThemeProvider theme={ardCiTheme}>
      <StyledHeader className="header">
        <Toolbar {...toolbarData} hasLogo hasCenteredLogo={false} fixed={true} />
        { showMobileNav && <div id="mobileNav">
          <ul>
            { toolbarData.items.map((item, index) => <li className={ item.isActive ? 'active' : '' } key={index}><a href="javascript:void(0);" onClick={() => _scrollTo(sectionRefs[index], -96, true) }><span>{ item.text }</span></a></li> ) }
          </ul>
        </div> }
      </StyledHeader>
      <main>
        <Root>
          <Row>
            <Column>
              <Content>
                <Section ref={sectionRefs[0]}>
                  <HomeSection />
                </Section>
                <hr/>
                <Section ref={sectionRefs[1]}>
                  <TvChannelsSection />
                </Section>
                <hr/>
                <Section ref={sectionRefs[2]}>
                  <RadioChannelsSection />
                </Section>
                <hr/>
                <Section ref={sectionRefs[3]}>
                  <ServicesReferencesSection />
                </Section>
                <hr/>
                <Section ref={sectionRefs[4]}>
                  <ContactSection />
                </Section>
              </Content>
            </Column>
          </Row>
        </Root>
      </main>
      <footer>
        <Footer>
          <FooterRow>
            <FooterColumn>
              <Row>
                <Column>
                  <nav><ul >
                    <li><Link to="/imprint">Imprint</Link></li>
                    <li><Link to="/privacy-policy">Privacy policy</Link></li>
                  </ul></nav>
                </Column>
              </Row>
              <Row>
                <Column p="0">
                  <Logos>
                    {Object.keys(channelLinks).map((channelKey, index) => 
                      <a key={index} href={channelLinks[channelKey]}>
                        { channelKey !== 'tagesschau24' ? <StyledChannelLogo key={channelKey} channel={channelKey} /> : <img className="footerLogo" src={tagesschau24Logo}/> }
                      </a>
                    )}
                  </Logos>
                </Column>
              </Row>
            </FooterColumn>
          </FooterRow>
        </Footer>
      </footer>
      <ScrollToTop />
    </ThemeProvider>
  );

}