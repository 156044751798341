
import React, { Component } from 'react';
import './App.css';
import AutoScrollToTop from './AutoScrollToTop'

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Layout from './Layout';
import Imprint from './Imprint';
import PrivacyPolicy from './PrivacyPolicy';

export default function App() {
  return (
    <div className="App">
      <Router basename={process.env.NODE_ENV === 'production' ? '/en' : '/'}>
        <AutoScrollToTop />
        <Switch>
          <Route path="/imprint">
            <Imprint/>
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy/>
          </Route>
          <Route path="/">
            <Layout />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}