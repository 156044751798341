import React from 'react';
import { StyledScrollToTop } from './styles';

export default function ScrollToTop() {
    return (
        <StyledScrollToTop>
            <a title="back to top" href="#">
                <span>back to top</span>
                <svg viewBox="0 0 48 48">
                    <path d="M32.6 22.8L24 14.1l-8.6 8.7a1 1 0 001.4 1.4L23 18v14a.9.9 0 001 1 1 1 0 001-1V17.9l6.2 6.3a1.1 1.1 0 00.7.3.9.9 0 00.7-.3 1 1 0 000-1.4z"></path>
                </svg>
            </a>
        </StyledScrollToTop>
    );
}