import React from 'react';

import {
  Link,
  useHistory
} from "react-router-dom";

import { Root, Content, Section, Footer, FooterRow, FooterColumn, Logos, StyledChannelLogo, StyledHeader } from './styles';

import { Toolbar } from '@ard-online/component-styleguide/dist/components/Toolbar/Toolbar';
import { ThemeProvider } from 'styled-components';
import { Row, Column } from '@ard-online/component-styleguide/dist/components/primitives/Grid';
import ardCiTheme from './ardCiTheme';

import ScrollToTop from './ScrollToTop';

export default function Imprint() {

  const [value, setValue] = React.useState('en');

  const history = useHistory();
  const handleClick = () => history.push('/');

  const channelLinks = {
    br: "https://www.br.de/br-fernsehen/",
    hr: "https://www.hr-fernsehen.de/",
    mdr: "https://www.mdr.de/tv",
    ndr: "https://www.ndr.de/fernsehen/",
    radiobremen: "https://dein.radiobremen.de/",
    rbb: "https://www.rbb-online.de/fernsehen",
    sr: "https://www.sr.de/sr/home/fernsehen/index.html",
    swr: "https://www.swrfernsehen.de/",
    wdr: "https://www1.wdr.de/fernsehen",
    dw: "https://www.dw.com/",
    daserste: "https://www.daserste.de/",
    one: "https://www.ardmediathek.de/one/",
    tagesschau24: "https://www.tagesschau24.de/",
    alpha: "https://www.ardmediathek.de/alpha/",
    '3sat': "https://www.3sat.de",
    arte: "https://www.arte.de",
    kika: "https://www.kika.de/",
    phoenix: "https://www.phoenix.de/",
    funk: "https://www.funk.net/",
    deutschlandradio: "https://www.deutschlandradio.de/",
    zdf: "https://www.zdf.de/",
  }

  const toolbarRef = React.createRef();

  const toolbarData = {
    actionIconsRight: [
      { icon: "account", text: value },
    ],
    items: [
      { text: "back to main page", href: '#', onClick: handleClick },
    ],
    logoButton: {
      as: Link,
      type: 'text',
      text: "back to start page",
      onClick: handleClick
    },
    accountNavigationItems: [
      { text: "deutsch", href: '/de', onClick: (e) => { setValue(e.target.innerText.substring(0, 2)) }},
      { text: "english" }
    ],
  }

  return (
    <ThemeProvider theme={ardCiTheme}>
      <StyledHeader ref={toolbarRef} className="header">
        <Toolbar {...toolbarData} hasLogo hasCenteredLogo={false} fixed={true} />
      </StyledHeader>
      <main>
        <Root>
          <Row>
            <Column>
              <Content>
                <Section>
                  <section>
                    <Row>
                      <Column>
                        <h2>Imprint</h2>
                        <p>ARD Channels International – a division of WDR mediagroup GmbH<br />
                        Ludwigstrasse 11<br />
                        50667 Köln<br />
                        Germany<br />
                        Tel.: +49 221 2035 1951<br />
                        Email: ard-channels-international@wdr-mediagroup.com</p>
                        <p>President of Supervisory Board: Julia Dalhoff-Schereik<br />
                        Managing Directors: Michael Loeb, Frank Nielebock<br />
                        Court of registration: Cologne district court HRB 19 51<br />
                        VAT ID number: DE 268 257 114</p>
                      </Column>
                      <Column>
                        <p>Before the renaming, the department ARD Channels International was known as ARD International Cable Coordination respectively ARD Kabelkoordination Ausland.</p>
                      </Column>
                      <Column>
                        <h3>Copyrights of images used</h3>
                        <p>
                          @ARD<br />
                          ARD/Stanislav Honzik<br />
                          ARD/SF DRS/ORF<br />
                          ARD Degeto/WDR/X Filme Creative Pool/Sky/Beta Film 2019/Frédéric Batier<br />
                          BR<br />
                          BR/THK/Nautilusfilm<br />
                          MDR/Svenja und Ralph Schieke<br />
                          NDR<br />
                          NDR/Thorsten Jander<br />
                          NDR/Rainer Mueller-Delin<br />
                          NDR/BBC<br />
                          NDR/Doclights GmbH/coraxfilm<br />
                          NDR/Doclights GmbH/BLACK CORAL FILMS AB<br />
                          NDR/MFG-Film/Till Lehmann<br />
                          NDR/NDR Naturfilm/Doclights/Macro Tele-Film Schiecke GbR/Svenja und Ralph Schieke<br />
                          NDR Naturfilm/Doclights/Hans-Peter Kuttler<br />
                          NDR/nonfictionplanet/Till Lehmann<br />
                          rbb/Andy Lehmann<br />
                          SWR/Sabine Hackenberg<br />
                          WDR<br />
                          WDR/Stephan Pick<br />
                          WDR/Markus Tedeskino<br />
                          WDR/Into Nature Productions<br />
                          WDR/Trickstudio Lutterbeck<br />
                          WDR/UFA Fiction/Willi Weber
                        </p>
                      </Column>
                    </Row>
                  </section>
                </Section>
              </Content>
            </Column>
          </Row>
        </Root>
      </main>
      <footer>
        <Footer>
          <FooterRow>
            <FooterColumn>
              <Row>
                <Column>
                  <nav><ul >
                    <li className="active"><strong>Imprint</strong></li>
                    <li><Link to="/privacy-policy">Privacy policy</Link></li>
                  </ul></nav>
                </Column>
              </Row>
              <Row>
                <Column p="0">
                  <Logos>
                    {Object.keys(channelLinks).map((channelKey, index) => 
                      <a key={index} href={channelLinks[channelKey]}><StyledChannelLogo key={channelKey} channel={channelKey} /></a>
                    )}
                  </Logos>
                </Column>
              </Row>
            </FooterColumn>
          </FooterRow>
        </Footer>
      </footer>
      <ScrollToTop />
    </ThemeProvider>
  );

}